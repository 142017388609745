import React, { useEffect, useState } from "react";
import style from "./Excel.module.css";
import { compile_vote_list } from "./utils";
import { useParams } from "react-router-dom";
import { getAllQuizResult } from "../../DAL/dal";
const ExcelJS = require("exceljs");

const Excel = ({
  caseName,
  result,
  setDeleteAllAnswers,
  setDeleteOneAnswers,
  questionsToLector,
  questions,
  cases,
}) => {
  // console.log(questions.questions);

  //__________________________________________________________
  // Точно такой же блок как и при визуализации квиза
  // Мне не нравится это решение, так как идут 2 одинаковых запроса из разных мест, в будущем это необходимо будет оптимизировать
  const [fullResult, setFullResult] = useState([]);
  const [standings, setStandings] = useState([]);
  const [formType, setFormType] = useState({}); // чтобы всегда знать какой тип формы приходит в конкретном квизе
  const [check, setCheck] = useState(false); // для того чтобы избежать бесконечного ререндера
  const [canRenderResult, setCanRenderResult] = useState(false); // сигнал что состояние массива standings готово для добавления результатов
  const { id } = useParams();
  useEffect(() => {
    getAllQuizResult(id).then((r) => {
      setFormType(r.data.form);
      setFullResult(r.data.result);
      // console.log(r.data.result)
    });
  }, []);
  useEffect(() => {
    for (let i in fullResult) {
      const { id, key, isCorrect, name, question, type, ...data } =
        fullResult[i];
      setStandings((prev) => [
        ...prev,
        {
          ...data,
          correct: 0,
          unCorrect: 0,
          list: [],
        },
      ]);
    }
  }, [fullResult]);
  useEffect(() => {
    if (standings.length && !check) {
      setStandings((prev) => {
        const arr = [];
        for (let key in formType) {
          arr.push(key);
        }
        return prev.filter(
          // производим фильтрацию для удаления повторяющихся объектов
          (v, i, a) =>
            a.findIndex((v2) => [...arr].every((k) => v2[k] === v[k])) === i
        );
      });
      setCheck(true);
      setCanRenderResult(true);
    }
  }, [standings]);

  // useEffect(() => {
  //   if ((standings.length, canRenderResult)) {
  //     const arr_final = structuredClone(standings);
  //     for (let i in fullResult) {
  //       for (let j in standings) {
  //         let check = true;
  //         for (let key in formType) {
  //           if (fullResult[i][key] == standings[j][key]) continue;
  //           else check = false;
  //         }
  //         if (check && fullResult[i].isCorrect) {
  //           arr_final[j].correct = arr_final[j].correct + 1;
  //           arr_final[j].list.push({
  //             question: fullResult[i].question,
  //             isCorrect: fullResult[i].isCorrect,
  //             name: fullResult[i].name,
  //             block: fullResult[i].block,
  //           });
  //         } else if (check && !fullResult[i].isCorrect) {
  //           arr_final[j].unCorrect = arr_final[j].unCorrect + 1;
  //           arr_final[j].list.push({
  //             question: fullResult[i].question,
  //             isCorrect: fullResult[i].isCorrect,
  //             name: fullResult[i].name,
  //             block: fullResult[i].block,
  //           });
  //         }
  //       }
  //     }
  //     setStandings(arr_final.sort((a, b) => b.correct - a.correct)); // лидеры вверху
  //     // console.log(arr_final.sort((a, b) => b.correct - a.correct));
  //   }
  // }, [canRenderResult]);

  //___________________________________________________________

  function uniqBy(a, key) {
    // функция убирающая повторяющиеся значения из массива
    var seen = {};
    return a.filter(function (item) {
      var k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }
  const exportExcelQuiz = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet_vote = workbook.addWorksheet("Результаты квиза");
    sheet_vote.properties.defaultRowHeight = 30;

    const arr = [];

    for (let i in fullResult) {
      arr.push(fullResult[i].block);
    }
    const result = uniqBy(
      ["Участник", ...arr, "Итого"],

      JSON.stringify
    );
    sheet_vote.columns = result.map((el) => {
      return {
        header: el,
        key: el,
        width: 40,
      };
    });

    /*
[
 {
  команда:'или все ключи с перс данными из formType', раунд:'',list:[{раунд:'результат начиная со второго вопроса'},...]
 }
]
*/

    const compileRows = (array) => {
      // функция которая формирует правильный массив для рендера строк в Exсel
      const result = [];

      const arr = []; // сюда возвращаем все уникальные ключи по перс данным

      for (let i in array) {
        const obj = {};
        const arrayPers = [];
        for (let key in formType) {
          arrayPers.push(array[i][key]);
          obj["Участник"] = arrayPers.join("/");
        }
        arr.push(obj);
      }
      result.push(...uniqBy(arr, JSON.stringify)); // на этом этапе добавляем кол-во объектов равное колличеству всех команд или участников

      // console.log(fullResult)
      // return

      for (let i in result) {
        result[i].list = [];
        for (let j in array) {
          const arrayPers = [];
          for (let key in formType) {
            arrayPers.push(array[j][key]);
          }
          const value = arrayPers.join("/");
          if (result[i]["Участник"] === value)
            if (!array[j].key) {
              if (array[j].bet)
                result[i][array[j].block] = array[j].isCorrect ? 2 : -2;
              else result[i][array[j].block] = array[j].isCorrect ? 1 : -1;
            } else if (array[j].key) {
              const { list, ...data } = result[i];
              if (data["Участник"]) delete data["Участник"];
              for (let data_key in data) {
                if (result[i].list[array[j].key - 1] == undefined)
                  if (array[j].bet)
                    result[i].list[array[j].key - 1] = {
                      ...result[i].list[array[j].key - 1],
                      [data_key]: array[j].isCorrect ? 2 : -2,
                    };
                  else
                    result[i].list[array[j].key - 1] = {
                      ...result[i].list[array[j].key - 1],
                      [data_key]: array[j].isCorrect ? 1 : -1,
                    };
                else {
                  if (
                    result[i].list[array[j].key - 1][data_key] != 2 &&
                    result[i].list[array[j].key - 1][data_key] != 1 &&
                    result[i].list[array[j].key - 1][data_key] != -2 &&
                    result[i].list[array[j].key - 1][data_key] != -1
                  )
                    if (array[j].bet)
                      result[i].list[array[j].key - 1][data_key] = array[j]
                        .isCorrect
                        ? 2
                        : -2;
                    else
                      result[i].list[array[j].key - 1][data_key] = array[j]
                        .isCorrect
                        ? 1
                        : -1;
                }
              }
            }
        }
      }

      // собираем под каждым раундом результаты

      for (let i in result) {
        const { Участник, list, ...data } = result[i];
        result[i].list.push({
          ...data,
          Участник: "Результаты по раундам",
          Итого: 0,
        });

        for (let j in result[i].list) {
          if (j < result[i].list.length - 1) {
            for (let key in data) {
              if (result[i].list[j][key])
                result[i].list[result[i].list.length - 1][key] +=
                  result[i].list[j][key];
              else continue;
            }
          } else {
            // суммирует все в итого
            const { Участник, ...values } = result[i].list[j];
            result[i].list[j].Итого = Object.values(values).reduce(
              (total, a) => total + a,
              0
            );
          }
        }
      }

      return result.sort(
        (a, b) =>
          b.list[b.list.length - 1].Итого - a.list[a.list.length - 1].Итого
      );
    };
    // console.log(fullResult)



    compileRows(fullResult).map((el, index) => {
      sheet_vote.addRow({
        ...el,
      });
      if (el.list.length) {
        el.list.map((li) => sheet_vote.addRow({ ...li }));
        sheet_vote.getRow((el.list.length + 1) * (index + 1) + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF00FF00" },
        };
      }
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `квиз.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet_vote = result?.length && workbook.addWorksheet("Голосование");
    const sheet_ask =
      questionsToLector.length && workbook.addWorksheet("Вопросы участников");
    if (result?.length) sheet_vote.properties.defaultRowHeight = 30;
    if (questionsToLector?.length) sheet_ask.properties.defaultRowHeight = 30;
    // это все для первого листа

    if (result?.length) {
      sheet_vote.columns = compile_vote_list(result, sheet_vote);
      compile_vote_list(
        result,
        sheet_vote,
        compile_vote_list(result, sheet_vote) // передаем саму же функцию в качестве 3 его параметра так как в этом случае она вернет нам колонки
      );
    }

    // это все для первого листа
    if (questionsToLector.length) {
      // это все для второго листа
      const compAskColumn = () => {
        // группирует все колонки
        const arr = [];
        for (let key in questionsToLector[0]) {
          if (key != "key")
            arr.push({
              header:
                key == "question"
                  ? "Вопрос"
                  : key == "first_name"
                  ? "Имя"
                  : key == "last_name"
                  ? "Фамилия"
                  : key == "isAnswered"
                  ? "Ответ лектора"
                  : "Популярность",
              key: key,
              width: 60,
            });
        }
        return arr;
      };

      sheet_ask.columns = compAskColumn();

      questionsToLector.map((el) => {
        return sheet_ask.addRow({
          question: el.question,
          first_name: el.first_name,
          last_name: el.last_name,
          range: el.range,
          isAnswered: el.isAnswered
            ? "Лектор ответил на вопрос"
            : "Ответа не было",
        });
      });
      sheet_ask.getRow(1).fill = {
        type: "pattern",
        pattern: "darkHorizontal",
        fgColor: { argb: "FFFF00" },
      };
      sheet_ask.getRow(1).font = {
        name: "Comic Sans MS",
        family: 4,
        size: 16,
        bold: true,
      };
      // это все для второго листа
    }

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `отчет.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <div className={style.linkContainer}>
        <button
          onClick={
            questions.questions.length || !cases.length
              ? exportExcel
              : exportExcelQuiz
          }
          className={style.csvLink}
        >
          Скачать Excel
        </button>
        {/* <button
            className={style.clearData}
            onClick={() => {
              setDeleteAllAnswers(true);
            }}
          >
            Удалить все ответы
          </button> */}
        {questions?.questions.length ? (
          <button
            className={style.clearData}
            onClick={() => {
              setDeleteOneAnswers(true);
            }}
          >
            Удалить ответы в данном блоке
          </button>
        ) : null}
      </div>
    </>
  );
};

export default Excel;
