import React, { useCallback, useEffect, useState } from "react";
import { changeFreeAnswerStatus, getAllQuizResult } from "../../DAL/dal";
import { useParams } from "react-router-dom";
import style from "./MainResults.module.css";

const VizualResultQuiz = ({
  reRender,
  clientTextColor,
  questionNameColor,
  blockNameColor,
  containerColor,
  result,
  setForGetCurrentResult,
  forGetCurrentResult,
}) => {
  const [fullResult, setFullResult] = useState([]);
  const [standings, setStandings] = useState([]);
  const [formType, setFormType] = useState({}); // чтобы всегда знать какой тип формы приходит в конкретном квизе
  const [check, setCheck] = useState(false); // для того чтобы избежать бесконечного ререндера
  const [canRenderResult, setCanRenderResult] = useState(false); // сигнал что состояние массива standings готово для добавления результатов
  const { id } = useParams();
  const [openAll, setOpenAll] = useState(false); // показывает сразу все результаты
  useEffect(() => {
    getAllQuizResult(id)
      .then((r) => {
        setFormType(r.data.form);
        setFullResult(r.data.result);
      })
      .catch((e) => console.log(e));
  }, []);
  useEffect(() => {
    for (let i in fullResult) {
      const { id, key, isCorrect, name, question, type, block, bet, ...data } =
        fullResult[i];
      setStandings((prev) => [
        ...prev,
        {
          ...data,
          isOpen: false,
          correct: 0,
          unCorrect: 0,
          list: [
            // { question: question, isCorrect: isCorrect }
          ],
        },
      ]);
    }
  }, [fullResult]);
  useEffect(() => {
    if (standings.length && !check) {
      setStandings((prev) => {
        const arr = [];
        for (let key in formType) {
          arr.push(key);
        }
        return prev.filter(
          // производим фильтрацию для удаления повторяющихся объектов
          (v, i, a) =>
            a.findIndex((v2) => [...arr].every((k) => v2[k] === v[k])) === i
        );
      });
      setCheck(true);
      setCanRenderResult(true);
    }
  }, [standings]);

  useEffect(() => {
    if (standings.length && canRenderResult) {
      const arr = structuredClone(standings);
      for (let i in fullResult) {
        for (let j in standings) {
          let check = true;
          for (let key in formType) {
            if (fullResult[i][key] == standings[j][key]) continue;
            else check = false;
          }
          if (check && fullResult[i].isCorrect) {
            if (fullResult[i].bet) arr[j].correct = arr[j].correct + 2;
            // если была сделана ставка то в этом случае прибавляем 2 очка
            else arr[j].correct = arr[j].correct + 1;
            arr[j].list.push({
              id: fullResult[i].id,
              key: fullResult[i].key,
              name: fullResult[i].name,
              question: fullResult[i].question,
              isCorrect: fullResult[i].isCorrect,
              type: fullResult[i].type ? fullResult[i].type : "variant",
              bet: fullResult[i].bet,
            });
          } else if (check && !fullResult[i].isCorrect) {
            if (fullResult[i].isCorrect != undefined) {
              if (fullResult[i].bet) arr[j].unCorrect = arr[j].unCorrect + 2;
              else arr[j].unCorrect = arr[j].unCorrect + 1;
            }
            arr[j].list.push({
              id: fullResult[i].id,
              key: fullResult[i].key,
              name: fullResult[i].name,
              question: fullResult[i].question,
              isCorrect: fullResult[i].isCorrect,
              type: fullResult[i].type ? fullResult[i].type : "variant",
              bet: fullResult[i].bet,
            });
          }
        }
      }
      setStandings(
        arr.sort((a, b) => b.correct - b.unCorrect - (a.correct - a.unCorrect))
      ); // лидеры вверху
    }
  }, [canRenderResult]);

  const changeCorrectOfFreeAnswer = (type, index, num, bet) => {
    // функция, отвечающая за выбор правильного/неправильного ответа в рамках вопроса со свободным ответом
    setStandings((prev) =>
      prev.map((el, prevIndex) => {
        if (prevIndex === index) {
          if (type) {
            if (bet) el.correct += 2;
            else el.correct += 1;
          } else {
            if (bet) el.unCorrect += 2;
            else el.unCorrect += 1;
          }
          el.list.map((q, prevNum) => {
            if (prevNum === num) {
              if (q.isCorrect == true && q.bet) el.correct -= 2;
              else if (q.isCorrect == true) el.correct -= 1;
              // else if (q.isCorrect == true && q.bet)
              //   el.correct -= 2; // учитываем ставку на вопрос
              if (q.isCorrect == false && q.bet) el.unCorrect -= 2;
              else if (q.isCorrect == false) el.unCorrect -= 1;
              // else if (q.isCorrect == false && q.bet) el.unCorrect -= 2; // учитываем ставку на вопрос
              changeFreeAnswerStatus(q.id, q.key, q.name, id, type).catch((e) =>
                console.log(e)
              );
              q.isCorrect = type;
            }
            return q;
          });
        }
        return el;
      })
    );
  };
  // function uniqBy(a, key) {
  //   // функция убирающая повторяющиеся значения из массива
  //   var seen = {};
  //   return a.filter(function (item) {
  //     var k = key(item);
  //     return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  //   });
  // }

  const clouseOpenAll = useCallback(() => {
    setStandings((prev) =>
      prev.map((el) => {
        el.isOpen = !openAll;
        return el;
      })
    );
    setOpenAll(!openAll);
  }, [openAll]);

  return (
    <div
      style={{
        backgroundColor: containerColor,
        minWidth: "90vw",
        overflowY: "auto",
        display: "block",
        padding: "10px 40px",
      }}
      className={style.vizualContainer}
    >
      <h1
        className={style.headCase}
        style={{
          color: blockNameColor,
          textAlign: "center",
          fontSize: "42px",
        }}
      >
        Результаты квиза
      </h1>
      <div onClick={clouseOpenAll} className={style.clouse_open_all_variants}>
        {openAll ? (
          <img
            title="Нажмите чтобы свернуть детали ответов"
            src={require("../../Images/eye.png")}
            alt=""
          />
        ) : (
          <img
            title="Нажмите чтобы посмотреть детали ответов"
            src={require("../../Images/eye-off.png")}
            alt=""
          />
        )}
      </div>
      {standings.map((el, index) => {
        const arr = [];
        // const arrayBlock = [];
        // for (let i in standings) {
        //   for (let j in standings[i].list) {
        //     arrayBlock.push(standings[i].list[j].id);
        //   }
        // }
        // const uniqBlocksArr = uniqBy(arrayBlock, JSON.stringify).map((el,n)=>{
        //   return {[n]:el}
        // });
        for (let key in formType) {
          arr.push(key);
        }
        arr.sort();
        return (
          <div
            style={{
              background: index % 2 ? "#3d3d3d88" : "",
              borderRadius: "20px",
              height: "max-content",
            }}
            key={index}
          >
            <div
              className={style.one_person_result}
              style={{
                borderBottom:
                  index < standings.length - 1 ? "1px solid white" : "",
              }}
            >
              <div
                style={{ display: "flex", gap: ".3vw", alignItems: "center" }}
              >
                <div
                  style={{
                    fontSize: "26px",
                    height: "max-content",
                    marginTop: "auto",
                  }}
                >
                  {index + 1}.
                </div>
                {arr.map((personKey, count) => {
                  if (arr[count] == "Команда") {
                    return (
                      <div key={count}>
                        <div className={style.person_name}>{el["Команда"]}</div>
                      </div>
                    );
                  } else if (
                    arr[count] != "Команда" &&
                    arr[count] != "Имя" &&
                    arr[count] != "Фамилия" &&
                    arr[count] != "Отчество"
                  )
                    return;
                  if (
                    personKey == "Имя" ||
                    personKey == "Фамилия" ||
                    personKey == "Отчество"
                  )
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                        key={count}
                      >
                        <div className={style.person_name}>{el[personKey]}</div>
                      </div>
                    );
                })}
                <span
                  className={style.is_open}
                  onClick={(e) => {
                    setStandings((prev) =>
                      prev.map((element, x) => {
                        if (x == index) element.isOpen = !el.isOpen;
                        return element;
                      })
                    );
                  }}
                >
                  {el.isOpen ? (
                    <img
                      title="Нажмите чтобы свернуть детали ответов"
                      src={require("../../Images/eye.png")}
                      alt=""
                    />
                  ) : (
                    <img
                      title="Нажмите чтобы посмотреть детали ответов"
                      src={require("../../Images/eye-off.png")}
                      alt=""
                    />
                  )}
                </span>
              </div>
              <div
                style={{
                  overflow: "hidden",
                  height: el.isOpen ? "auto" : "1px",
                  background: el.isOpen ? "" : "grey",
                }}
              >
                {el.list.map((q, num) => {
                  // if (q.id)
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "1vw",
                        padding: "5px",
                        borderRadius: "10px",
                        background: num % 2 ? "#3d3d3d88" : "",
                      }}
                      key={num}
                    >
                      <span
                        style={{
                          whiteSpace: "normal",
                          fontWeight: q.bet ? "900" : "100",
                          color: q.bet ? "gold" : "",
                        }}
                      >
                        {q.question}
                        {q.bet && " +1"}
                      </span>
                      <span
                        style={{
                          color: q.isCorrect ? "green" : "red",
                        }}
                      >
                        {q.type == "free" ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "15px",
                              color:
                                q.isCorrect == undefined
                                  ? "white"
                                  : q.isCorrect
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {q.name}
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <span>верно</span>
                                <input
                                  type="checkbox"
                                  name="верно"
                                  value={q.isCorrect}
                                  checked={
                                    q.isCorrect && q.isCorrect != undefined
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    changeCorrectOfFreeAnswer(
                                      true,
                                      index,
                                      num,
                                      q.bet // является ли вариант ставкой
                                    )
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <span>не_верно</span>
                                <input
                                  type="checkbox"
                                  name="не верно"
                                  value={q.isCorrect}
                                  checked={
                                    !q.isCorrect && q.isCorrect != undefined
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    changeCorrectOfFreeAnswer(
                                      false,
                                      index,
                                      num,
                                      q.bet
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ) : q.isCorrect ? (
                          <>{q.name} / Верно</>
                        ) : (
                          <>{q.name} / Не верно</>
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div style={{ margin: "0 0 0 auto" }}>
                <div
                  className={style.correct_uncorrect_counts}
                  style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}
                >
                  <span>Правильные ответы:</span>
                  <span>{el.correct}</span>
                </div>
                <div className={style.correct_uncorrect_counts}>
                  <span>Неправильные ответы:</span>
                  <span>{el.unCorrect}</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VizualResultQuiz;
