import { refresh } from "../DAL/dal"
import config from "../components/AdminPage/Authorization/config"


const inMemoryJWTService = () => {
    let inMemoryJWT = null
    let refreshTimeoutId = null

    const refreshToken = (expiretion) => { // функция отвечающая за обновления accessToken как при монтировании компоненты так и при окончании срока жизни
        const timeoutTrigger = expiretion - 10000
        refreshTimeoutId = setTimeout(() => {
            refresh()
                .then((r) => {
                    const { accessToken, accessTokenExpiration } = r.data;
                    setToken(accessToken, accessTokenExpiration);
                })
                .catch(err => console.log(err));
        }, timeoutTrigger)
    }

    const aboartRefreshToken = () => {
        if (refreshTimeoutId) clearInterval(refreshTimeoutId)
    }

    const getToken = () => inMemoryJWT


    const setToken = (token, tokenExpiration) => {
        inMemoryJWT = token
        refreshToken(tokenExpiration)
    }
    const deleteToken = () => {
        inMemoryJWT = null
        aboartRefreshToken()
        window.localStorage.setItem(config.LOGOUT_STORAGE_KEY, Date.now())
    }
    return { getToken, setToken, deleteToken }
}

export default inMemoryJWTService()