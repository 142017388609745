import React, { createRef, useEffect, useRef, useState } from "react";
import styles from "./Admin.module.css";
import { getAllPreviousImages } from "../../DAL/dal";

const StylesCase = ({
  colors,
  setColors,
  setFileData,
  previewImage,
  setPreviewImage,
  blockQuestions,
  height,
}) => {
  let photo = createRef();
  const [previousUserBackgrounds, setPreviousUserBackgrounds] = useState([]);

  const addColors = (e) => {
    if (e.target.name != "buttonsBackground" && e.target.name != "buttonsColor")
      setColors((state) => ({ ...state, [e.target.name]: e.target.value }));
    if (e.target.name === "buttonsBackground")
      setColors((state) => ({
        ...state,
        buttonsColor: state.buttonsColor.map((el, index) => {
          if (index === 0) {
            return (el = e.target.value);
          }
          return el;
        }),
      }));
    if (e.target.name === "buttonsColor")
      setColors((state) => ({
        ...state,
        buttonsColor: state.buttonsColor.map((el, index) => {
          if (index === 1) {
            return (el = e.target.value);
          }
          return el;
        }),
      }));
  };
  const addPhoto = (e) => {
    try {
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
      setFileData(photo.current.files[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllPreviousImages().then((r) => {
      function uniqBy(a, key) {
        // функция убирающая повторяющиеся значения из массива
        var seen = {};
        return a.filter(function (item) {
          var k = key(item);
          return seen.hasOwnProperty(k) ? false : (seen[k] = true);
        });
      }
      setPreviousUserBackgrounds(uniqBy(r, JSON.stringify));
    });
  }, []);

  return (
    <section
      style={
        window.location.hash.slice(0, 23) == "#/admin/current-session"
          ? { flexDirection: "column" }
          : { height: height * 0.68 }
      }
      className={styles.colorsSection}
    >
      <div className={styles.chooseImage}>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className={styles.reset_img}
            src={require("../../Images/reset.png")}
            alt=""
            title="Сбросить изображение"
            onClick={() => {
              setFileData(null);
              setPreviewImage(
                "https://api-congress.pointreg.ru/backgrounds/default.png"
              );
            }}
          />
          <img src={previewImage} alt="" />
          {blockQuestions?.length ? ( // добавляем превью контейнера только если присутствуют вопросы
            <div
              style={{ background: colors.containerColor }}
              className={styles.pvewiew_container}
            >
              <h2 style={{ color: colors.blockNameColor }}>
                {blockQuestions[0].caseName}
              </h2>
              <h3 style={{ color: colors.questionNameColor }}>
                Новый вопрос доступен для голосования
              </h3>
              <div
                style={{
                  background: colors.buttonsColor[0],
                  color: colors.buttonsColor[1],
                }}
              >
                Начать
              </div>
            </div>
          ) : null}
        </div>
        <label className={styles.label} htmlFor="uploadImage">
          <input
            accept=".jpg,.jpeg,.png"
            className={styles.uploadImage}
            name="uploadImage"
            id="uploadImage"
            type="file"
            ref={photo}
            onChange={addPhoto}
          />
          <span>Загрузить фон</span>
          <img
            src={require("../../Images/paperclip.png")}
            alt=""
            title="Выбрать изображение"
          />
        </label>
        {window.location.hash == "#/admin/create-session/styles" &&
        previousUserBackgrounds.length ? ( // только для первичного создания сессии
          <div className={styles.previousImages}>
            <h2 style={{ margin: "5px auto" }}>
              Ранее используемые изображения
            </h2>
            <div className={styles.previousImagesContainer}>
              {previousUserBackgrounds.map((image, index) => (
                <div style={{ overflow: "hidden" }} key={index}>
                  <img
                    className={styles.prevImage}
                    onClick={() => {
                      setFileData(null);
                      setPreviewImage(image.backgroundimage);
                    }}
                    src={image.backgroundimage}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.all_colors}>
        <img
          onClick={() =>
            // сбрасываем цвета до первоначальных
            setColors({
              clientTextColor: "#FFFFFF",
              containerColor: "rgba(0, 0, 0, 0.5)",
              variantActive: "#00D079",
              blockNameColor: "#FFFFFF",
              questionNameColor: "#949494",
              buttonsColor: ["#2A2B9C", "#FFFFFF"],
            })
          }
          className={styles.reset_img_2}
          src={require("../../Images/reset.png")}
          alt=""
          title="Сбросить цвета до первоначальных"
        />
        <h2>Цветовая схема</h2>
        <div className={styles.chooseColors}>
          <input
            name="clientTextColor"
            type="color"
            value={colors.clientTextColor}
            onChange={addColors}
          />
          <div>
            <h3>Цвет текста</h3>
            <input
              className={styles.type_input}
              name="clientTextColor"
              type="text"
              value={colors.clientTextColor}
              onChange={addColors}
            />
          </div>
        </div>
        <div className={styles.chooseColors}>
          <input
            name="containerColor"
            value={colors.containerColor}
            type="color"
            onChange={addColors}
          />
          <div>
            <h3>Цвет фона подложки панелей</h3>
            <input
              className={styles.type_input}
              name="containerColor"
              value={colors.containerColor}
              type="text"
              onChange={addColors}
            />
          </div>
        </div>
        <div className={styles.chooseColors}>
          <input
            name="variantActive"
            value={colors.variantActive}
            type="color"
            onChange={addColors}
          />
          <div>
            <h3>Цвет активного варианта</h3>
            <input
              className={styles.type_input}
              name="variantActive"
              value={colors.variantActive}
              type="text"
              onChange={addColors}
            />
          </div>
        </div>
        <div className={styles.chooseColors}>
          <input
            name="blockNameColor"
            value={colors.blockNameColor}
            type="color"
            onChange={addColors}
          />
          <div>
            <h3>Цвет заголовка блока вопросов</h3>
            <input
              className={styles.type_input}
              name="blockNameColor"
              value={colors.blockNameColor}
              type="text"
              onChange={addColors}
            />
          </div>
        </div>
        <div className={styles.chooseColors}>
          <input
            name="questionNameColor"
            value={colors.questionNameColor}
            type="color"
            onChange={addColors}
          />
          <div>
            <h3>Цвет заголовка вопроса</h3>
            <input
              className={styles.type_input}
              name="questionNameColor"
              value={colors.questionNameColor}
              type="text"
              onChange={addColors}
            />
          </div>
        </div>
        <div className={styles.chooseColors}>
          <input
            name="buttonsBackground"
            value={colors.buttonsColor[0]}
            type="color"
            onChange={addColors}
          />
          <div>
            <h3>Цвета фона кнопок</h3>
            <input
              className={styles.type_input}
              name="buttonsBackground"
              value={colors.buttonsColor[0]}
              type="text"
              onChange={addColors}
            />
          </div>
        </div>
        <div className={styles.chooseColors}>
          <input
            name="buttonsColor"
            value={colors.buttonsColor[1]}
            type="color"
            onChange={addColors}
          />
          <div>
            <h3>Цвет текста кнопок</h3>
            <input
              className={styles.type_input}
              name="buttonsColor"
              value={colors.buttonsColor[1]}
              type="text"
              onChange={addColors}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default StylesCase;
