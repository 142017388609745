import React, { useEffect, useRef, useState } from "react";
import style from "./questionsOnScale.module.css";
import Range from "../RangeComponent/Range";
import { useDispatch, useSelector } from "react-redux";
import {
  addFreeAnswerOtherVariantAction,
  checkedDontShowVariantAction,
} from "../../Redux/AnswersReducer";

const QuestionsOnScale = ({
  currentBlock,
  currentGlobalQuestionsIndex,
  buttonsColor,
  variantActive,
  questionNameColor,
  currentCaseName,
  blockNameColor,
}) => {
  const [arrayRange, setSections] = useState([]);

  useEffect(() => {
    setSections(Array.apply(null, Array(currentBlock.range.range)));
  }, [currentBlock]);


  return (
    <section className={style.mainOnScale}>
      <h2 style={{ color: blockNameColor }} className={style.head}>
        {currentCaseName}
      </h2>
      <h3 style={{ color: questionNameColor }} className={style.head_h3}>
        {currentBlock.blockName}
      </h3>
      <div className={style.stars}>
        {arrayRange.map((li, index) => (
          <Range
            buttonsColor={buttonsColor}
            variantActive={variantActive}
            key={index}
            number={index + 1}
            currentGlobalQuestionsIndex={currentGlobalQuestionsIndex}
          />
        ))}
      </div>
    </section>
  );
};

export default QuestionsOnScale;
