import { useEffect, useState } from "react";
import styles from "./elements.module.css";
import { useNavigate } from "react-router-dom";
import {
  activeSessionStatus,
  createNewAskOrDelete,
  deleteSession,
  endedTime,
  forgetPass,
  getTariffOfCurrentUser,
  getUser,
  minusOneSession,
  refresh,
  setTariff,
} from "../../../DAL/dal";
import inMemoryJWTService from "../../../inMemoryJWT/inMemoryJWT";
import { useDispatch } from "react-redux";
import { setUserDataAction } from "../../../Redux/UserReducer";

export const EditBlockName = ({
  setState,
  index,
  setEditMode,
  setEditModeType,
  state,
}) => {
  const [localText, setLocalText] = useState("");

  useEffect(() => {
    if (state[index]?.caseName != undefined)
      setLocalText(state[index].caseName);
    // разово инициализируем локальное состояние занося имя конкретного блока
    else setLocalText(state);
  }, []);

  const localChangeInput = (e) => {
    setLocalText(e.target.value);
  };

  return (
    <section className={styles.main_block}>
      {state[index]?.caseName != undefined ? (
        <h2>Введите название блока вопросов</h2>
      ) : (
        <h2>Введите название сессии</h2>
      )}
      <input
        autoFocus={true}
        type="text"
        placeholder="Название блока"
        value={localText}
        onChange={localChangeInput}
      />
      <div className={styles.edit_buttons}>
        <button
          onClick={() => {
            setEditMode(false);
            setEditModeType("");
          }}
        >
          <img src={require("../../../Images/x-square.png")} alt="" />
          Отменить
        </button>
        <button
          onClick={() => {
            if (state[index]?.caseName != undefined) setState(localText, index);
            else setState(localText);
            setEditMode(false);
            setEditModeType("");
          }}
        >
          <img src={require("../../../Images/save.png")} alt="" />
          Сохранить
        </button>
      </div>
    </section>
  );
};

export const NewSessionIsCreated = ({ setEditMode, currentSessionId }) => {
  const navigate = useNavigate();
  const navigateToNewSession = () => {
    navigate(`/admin/current-session/${currentSessionId}`);
    setEditMode(false);
  };

  return (
    <section className={styles.main_block}>
      <h2>Новая сессия успешно создана!</h2>
      <div className={styles.go_to_new_session} onClick={navigateToNewSession}>
        <span>→</span> Продолжить
      </div>
    </section>
  );
};

export const DeleteSession = ({
  setEditMode,
  id,
  setAllSessions,
  setEditModeType,
  sessionName,
}) => {
  const navigate = useNavigate();
  const delSession = () => {
    deleteSession(id).then((r) => {
      setAllSessions((prev) => prev.filter((el) => el.sessionId != id));
      setEditModeType("");
      setEditMode(false);
      navigate("/admin");
    });
  };

  return (
    <section className={styles.main_block}>
      <h2 className={styles.h2_delete_session}>
        Удалить сессию <br /> {sessionName}?
      </h2>
      <div className={styles.buttons_yes_now_container}>
        <button className={styles.button_yes} onClick={delSession}>
          Да
        </button>
        <button
          className={styles.button_now}
          onClick={() => {
            setEditModeType("");
            setEditMode(false);
          }}
        >
          Нет
        </button>
      </div>
    </section>
  );
};

export const AlertModal = ({
  info,
  setEditMode,
  setEditModeType,
  ancillary = false,
  editModeType = "",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCheck, setIsCheck] = useState(
    window.localStorage.getItem("isFirstToAsk") ? true : false
  );
  const [isCheckQuiz, setIsCheckQuiz] = useState(
    window.localStorage.getItem("isFirstQuiz") ? true : false
  );
  const checkFunc = (type) => {
    switch (type) {
      case "ask":
        if (window.localStorage.getItem("isFirstToAsk")) {
          setIsCheck(false);
          window.localStorage.removeItem("isFirstToAsk");
        } else {
          window.localStorage.setItem("isFirstToAsk", "ok");
          setIsCheck(true);
        }
        break;
      case "quiz":
        if (window.localStorage.getItem("isFirstQuiz")) {
          setIsCheckQuiz(false);
          window.localStorage.removeItem("isFirstQuiz");
        } else {
          window.localStorage.setItem("isFirstQuiz", "ok");
          setIsCheckQuiz(true);
        }
        break;
      default:
        break;
    }
  };

  return (
    <section className={styles.main_block}>
      <h2 className={styles.h2_delete_session}>{info}</h2>
      <div style={{ display: "flex", gap: "5vh", flexDirection: "column" }}>
        <button
          onClick={() => {
            if (editModeType == "passIsChanged") {
              navigate("/admin");
              refresh()
                .then((r) => {
                  const { accessToken, accessTokenExpiration } = r.data;
                  inMemoryJWTService.setToken(
                    accessToken,
                    accessTokenExpiration
                  );
                  getUser()
                    .then((res) => {
                      getTariffOfCurrentUser().then((response) => {
                        // уточняем тариф у конкретного пользователя
                        const { name, email, role } = res.data;
                        dispatch(
                          setUserDataAction(name, email, role, response)
                        );
                      });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            setEditModeType("");
            setEditMode(false);
          }}
          className={styles.button_now}
        >
          Ok
        </button>
        {ancillary && !editModeType ? (
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <div>Больше не показывать</div>
            <div
              onClick={() => checkFunc("ask")}
              className={styles.check_input}
            >
              <span
                style={
                  isCheck
                    ? { opacity: 1 }
                    : { opacity: 0, transform: "translateX(100px)" }
                }
              >
                ✓
              </span>
            </div>
          </div>
        ) : ancillary && editModeType ? (
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <div>Больше не показывать</div>
            <div
              onClick={() => checkFunc("quiz")}
              className={styles.check_input}
            >
              <span
                style={
                  isCheckQuiz
                    ? { opacity: 1 }
                    : { opacity: 0, transform: "translateX(100px)" }
                }
              >
                ✓
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};
export const ActivitySession = ({
  setEditMode,
  setEditModeType,
  id,
  isActive,
  setIsActive,
  setRerender,
}) => {
  return (
    <section className={styles.main_block}>
      <h2 className={styles.h2_delete_session}>Внимание!</h2>
      <p className={styles.information}>
        После активации сессии у Вас будет списано одно голосование и
        редактирование вопросов будет доступно еще 3 дня. Рекомендуем еще раз
        все проверить перед активацией!
      </p>
      <div className={styles.active_or_close_buttons}>
        <button
          onClick={() => {
            setEditModeType("");
            setEditMode(false);
          }}
          className={styles.button_now}
        >
          Отмена
        </button>
        <button
          style={{ border: "1px solid white" }}
          onClick={() => {
            activeSessionStatus(id).then((r) => {
              const time = new Date().getTime() + 259200000;
              // const time = new Date().getTime() + 120000;
              endedTime(id, time).then((r) => {
                minusOneSession().then((res) => {
                  setIsActive(!isActive);
                  setEditModeType("");
                  setEditMode(false);
                });
              });
            });
          }}
          className={styles.button_now}
        >
          Активировать
        </button>
      </div>
    </section>
  );
};

export const ChangeTariff = ({
  // чисто для меня
  setEditMode,
  user,
  tariff,
  user_id,
}) => {
  return (
    <section className={styles.main_block}>
      <h2 className={styles.h2_delete_session}>
        Назначить пользователю {user.name} тариф {tariff}
      </h2>
      <div className={styles.active_or_close_buttons}>
        <button
          onClick={() => setEditMode(false)}
          className={styles.button_now}
        >
          Нет
        </button>
        <button
          style={{ border: "1px solid white" }}
          className={styles.button_now}
          onClick={() => {
            setTariff(
              user_id,
              tariff == "full year" ? "fullyear" : tariff
            ).then((r) => {
              setEditMode(false);
            });
          }}
        >
          Да
        </button>
      </div>
    </section>
  );
};

export const AddAsk = ({ isAsk, id, setEditMode, setIsAsk }) => {
  const changeAsyncFunction = () => {
    if (isAsk) {
      createNewAskOrDelete(id, false).then((r) => setIsAsk(false));
    } else {
      createNewAskOrDelete(id, true).then((r) => setIsAsk(true));
    }
    setEditMode(false);
  };
  return (
    <section className={styles.main_block}>
      <h2 className={styles.h2_delete_session}>
        {isAsk
          ? "Отключить модуль <<Вопросы спикеру>>?"
          : "Добавить модуль <<Вопросы спикеру>>?"}
      </h2>
      <div className={styles.active_or_close_buttons}>
        <button
          onClick={() => setEditMode(false)}
          className={styles.button_now}
        >
          Нет
        </button>
        <button
          style={{ border: "1px solid white" }}
          className={styles.button_now}
          onClick={changeAsyncFunction}
        >
          Да
        </button>
      </div>
    </section>
  );
};

export const ChangePassModal = ({
  setEditModeType,
  setForEditMode,
  setEditMode,
}) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [inputState, setInputState] = useState("");
  const [onLoad, setOnLoad] = useState(false);

  const onTypeEmail = (e) => {
    const validator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
      e.target.value
    );
    setInputState(e.target.value);
    if (!validator) setIsValidEmail(false);
    else setIsValidEmail(true);
  };
  useEffect(() => {
    if (!inputState.length) setIsValidEmail(true);
  }, [inputState]);

  return (
    <div
      style={{ margin: "0 0 auto 0", width: "100%" }}
      className={styles.email_for_change_pass_div}
    >
      <h2>Введите адрес электронной почты указанный при регистрации</h2>
      <input
        style={{
          borderBottom: isValidEmail
            ? "1px solid rgba(255, 255, 255, 0.5)"
            : "1px solid red",
          width: "100%",
        }}
        type="text"
        placeholder="Электронная почта"
        value={inputState}
        onChange={onTypeEmail}
      />
      <div className={styles.buttons_div_for_change_pass}>
        <button onClick={() => setEditModeType("")}>← Назад</button>
        <button
          onClick={() => {
            if (isValidEmail && inputState.length) {
              setOnLoad(true);
              forgetPass(inputState)
                .then((r) => {
                  setEditModeType("");
                  setForEditMode("Информация направлена на указанную почту");
                  setEditMode(true);
                  setOnLoad(false);
                })
                .catch((err) => {
                  setOnLoad(false);
                  const { data, status } = err.response;
                  if (status === 403) {
                    setEditModeType("");
                    setForEditMode(
                      "Пользователь с таким почтовым адресом не найден"
                    );
                    setEditMode(true);
                  }
                });
            } else if (!isValidEmail) {
              setEditModeType("");
              setForEditMode("Некорректное почтовое имя");
              setEditMode(true);
            } else {
              setEditModeType("");
              setForEditMode("Поле не может быть пустым");
              setEditMode(true);
            }
          }}
        >
          {onLoad ? <div className={styles.loading}></div> : null}
          Отправить
        </button>
      </div>
    </div>
  );
};
