import React, { useEffect, useState } from "react";
import styles from "./MainResults.module.css";

const VizualQuestionsToLector = ({
  questions,
  containerColor,
  blockNameColor,
  questionNameColor,
  clientTextColor,
}) => {
  return (
    <>
      <section
        style={{ background: containerColor }}
        className={styles.main_questions_to_lector}
      >
        <h2
          style={{ color: blockNameColor }}
          className={styles.main_questions_to_lector_title}
        >
          Рейтинг вопросов{" "}
          <span style={{ color: questionNameColor }}>Топ 5</span>
        </h2>
        <div className={styles.scroll_container}>
          {questions.map((el, index) => {
            return (
              <div
                style={{ background: index % 2 ? "#3d3d3d88" : "none" }}
                key={index}
                className={styles.current_approve_question}
              >
                <div
                  style={{
                    color: questionNameColor,
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  {index <= 4 && (
                    <span
                      style={{
                        minWidth: "40px",
                        height: "40px",
                        maxHeight: "40px",
                        borderRadius: "50%",
                        color: "black",
                        background: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "GrandisExtended-light",
                        fontSize: "22px",
                        fontWeight: 900,
                      }}
                    >
                      {index + 1}
                    </span>
                  )}
                  <span style={{ opacity: el.isAnswered ? "0.2" : "1" }}>
                    {el.question}
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      bottom: "5px",
                      right: "10px",
                    }}
                  >
                    {el.first_name + " " + el.last_name}
                  </span>
                </div>
                <div className={styles.range}>
                  <img src={require("../../Images/star.png")} alt="" />
                  <span>{el.range}</span>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default VizualQuestionsToLector;
