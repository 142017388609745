import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Colors } from 'chart.js';

export function TestDiagramm({ width = '800px', resultCounters = null, colors }) {
    ChartJS.register(ArcElement, Tooltip, Colors);
    ChartJS.defaults.color = 'white';



    const data = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: resultCounters ? resultCounters : [12, 9, 3, 5, 2, 3],
                // backgroundColor: [
                //     'rgba(255, 99, 132, 0.7)',
                //     'rgba(54, 162, 235, 0.7)',
                //     'rgba(255, 206, 86, 0.7)',
                //     'rgba(75, 192, 192, 0.7)',
                //     'rgba(153, 102, 255, 0.7)',
                //     'rgba(255, 159, 64, 0.7)',
                // ],
                backgroundColor: colors,

                // borderColor: [
                //     'rgba(255, 99, 132, 1)',
                //     'rgba(54, 162, 235, 1)',
                //     'rgba(255, 206, 86, 1)',
                //     'rgba(75, 192, 192, 1)',
                //     'rgba(153, 102, 255, 1)',
                //     'rgba(255, 159, 64, 1)',
                // ],
                borderColor: 'white',
                borderWidth: 2,
            },
        ],
    };
    return <section style={{ width: width }}><Pie data={data} /></section>;
}
