import React, { useEffect, useState } from "react";
import style from "./questionsChoiceOfOptionsMany.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addFreeAnswerOtherVariantAction,
  chackedVariantsAction,
} from "../../Redux/AnswersReducer";

const QuestionsChoiceOfOptionsMany = ({
  currentBlock,
  currentGlobalQuestionsIndex,
  clientTextColor,
  variantActive,
  blockNameColor,
  questionNameColor,
  currentCaseName,
  buttonsColor,
}) => {
  const { answers } = useSelector((state) => state.answers);
  const dispatch = useDispatch();
  const [opacityVariants, setOpacityVariants] = useState(false);
  const changeFreeAnswerValue = (e) => {
    dispatch(
      addFreeAnswerOtherVariantAction(
        currentBlock.blockName,
        e.currentTarget.value
      )
    );
  };
  return (
    <section className={style.head_question_container}>
      <h2 style={{ color: blockNameColor }} className={style.head}>
        {currentCaseName}
      </h2>
      <h3 style={{ color: questionNameColor }} className={style.head_h3}>
        {currentBlock.blockName}
      </h3>
      <div className={style.main}>
        {currentBlock.list?.map((li, index) => {
          if (li?.answer != undefined) {
            return (
              <input
                type="text"
                placeholder="Ваш вариант ответа"
                className={style.textInput}
                key={index}
                onChange={changeFreeAnswerValue}
                value={answers[currentGlobalQuestionsIndex]?.list[index].answer}
              />
            );
          } else {
            return (
              <div
                onClick={() => {
                  dispatch(
                    chackedVariantsAction(currentBlock.blockName, li.name)
                  );
                }}
                style={
                  answers[currentGlobalQuestionsIndex]?.list[index].isChecked
                    ? { background: variantActive, color: clientTextColor }
                    : opacityVariants &&
                      !answers[currentGlobalQuestionsIndex]?.list[index]
                        .isChecked
                    ? {}
                    : { color: clientTextColor, background: buttonsColor[0] }
                }
                className={style.mainChildren}
                key={index}
              >
                {li.name}
                {answers[currentGlobalQuestionsIndex]?.list[index]
                  .isChecked && <span className={style.clouse}>✕</span>}
              </div>
            );
          }
        })}
      </div>
    </section>
  );
};

export default QuestionsChoiceOfOptionsMany;
