const SET_USER_DATA = 'SET_USER_DATA'
const SET_EXIT = 'SET_EXIT'


let initialStateQuestions = {
    login: false,
    name: '',
    email: '',
    role: '',
    tariff: null
};

const userState = (state = initialStateQuestions, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                name: action.name,
                email: action.email,
                role: action.role,
                login: true,
                tariff: action.tariff
            }
        case SET_EXIT:
            return {
                ...state,
                login: false
            }
        default:
            return state;
    }
};

export const setUserDataAction = (name, email, role, tariff) => {
    return { type: SET_USER_DATA, name, email, role, tariff }
}
export const exitAction = () => {
    return { type: SET_EXIT }
}


export default userState;
