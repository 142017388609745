import React, { useCallback } from "react";
import CurrentQuestions from "./Questions-Types/One_Variant/CurrentQuestions";
import { useEffect, useState } from "react";
import styles from "./Styles.module.css";
import Scale from "./Questions-Types/Questions_On_Scale/Scale";
import QuizOneOrManyVariant from "./Questions-Types/Quiz/QuizOneOrManyVariant";
import QuizFreeText from "./Questions-Types/Quiz/QuizFreeText";

const BlockQuestionsComponent = ({
  index,
  blockQuestions,
  setBlockQuestions,
  setAlertInfo,
  setEditMode,
  hideBlock,
}) => {
  const dragFunc = (e, index) => {
    setArrayDragStyle(
      arrayDragStyle.map((el, count) => {
        if (count == index) {
          return {
            ...el,
            transform: `translateY(${e.nativeEvent.offsetY / 2}vh)`,
            transition: ".5s",
          };
        }
        return el;
      })
    );
  };
  const [arrayDragStyle, setArrayDragStyle] = useState([]);

  function moveNew(key, index, arr) {
    // функция по перетаскиванию элементов, меняет их порядок в массиве
    const result = structuredClone(arr);
    if (key > 50) {
      if (index == result.length - 1) return result;
      const currentEl = result[index];
      const elNext = result[index + 1];
      result[index + 1] = currentEl;
      result[index] = elNext;
    } else if (key < -50) {
      if (index == 0) return result;
      const currentEl = result[index];
      const elNext = result[index - 1];
      result[index - 1] = currentEl;
      result[index] = elNext;
    }
    return result;
  }
  useEffect(() => {
    setArrayDragStyle(
      blockQuestions[index].questions.map((el) => {
        return {};
      })
    );
  }, [blockQuestions]);

  const addQuestionOnCurrentBlock = () => {
    if (setBlockQuestions == null) return;
    // функция добавления очередного вопроса в блок
    let count = 0;
    for (let i in blockQuestions[index].questions) {
      if (
        blockQuestions[index].questions[i].type != "questionsQuizOne" &&
        blockQuestions[index].questions[i].type != "questionsQuizMany" &&
        blockQuestions[index].questions[i].type != "questionsQuizFreeText"
      ) {
        count += 1;
      }
    }
    if (count < 10) {
      setBlockQuestions((prev) =>
        prev.map((el, count) => {
          return count === index
            ? {
                ...el,
                questions: [
                  ...el.questions,
                  {
                    blockName: "",
                    type: "questionsChoiceOfOptionsOne",
                    list: [
                      {
                        name: "",
                        isChecked: false,
                        id: el.id,
                      },
                      {
                        name: "",
                        isChecked: false,
                        id: el.id,
                      },
                    ],
                  },
                ],
              }
            : el;
        })
      );
    } else {
      // setAlertInfo(
      //   "Больше 5-ти вопросов в блоке допустимо только в рамках квиза"
      // );
      setEditMode(true);
    }
  };

  // функции и состояния, связанные с редактированием типов вопросов
  const [translate, setTranslate] = useState([]);
  const changeTranslate = (value, number) => {
    setTranslate((prev) =>
      prev.map((el, count) => {
        if (count == number) el = value;
        return el;
      })
    );
  };

  const changeQuestionType = useCallback(
    (type, questionIndex) => {
      // функция отвечающая за изменение типа вопроса
      if (setBlockQuestions == null) return; // для простых смертных после активации сессии редактирование невозможно

      setBlockQuestions((prev) =>
        prev.map((el, count) => {
          return count === index
            ? {
                ...el,
                questions: el.questions.map((q, number) => {
                  if (
                    type == "questionsChoiceOfOptionsOne" ||
                    type == "questionsChoiceOfOptionsMany"
                  ) {
                    return number === questionIndex
                      ? {
                          ...q,
                          type: type,
                          list:
                            q.type === "questionsChoiceOfOptionsOne" ||
                            q.type === "questionsChoiceOfOptionsMany"
                              ? q.list
                              : [
                                  {
                                    name: "",
                                    isChecked: false,
                                    id: el.id,
                                  },
                                  {
                                    name: "",
                                    isChecked: false,
                                    id: el.id,
                                  },
                                ],
                        }
                      : q;
                  } else if (type == "questionsOnScale") {
                    if (number === questionIndex) {
                      const { list, ...obj } = q;
                      return {
                        ...obj,
                        type: type,
                        range: { range: 0, choose: 0, id: el.id },
                      };
                    } else {
                      return q;
                    }
                  } else if (
                    type == "questionsQuizOne" ||
                    type == "questionsQuizMany"
                  ) {
                    if (number === questionIndex) {
                      const { list, ...obj } = q;
                      return {
                        ...obj,
                        type: type,
                        list: [
                          {
                            name: "",
                            isChecked: false,
                            id: el.id,
                            isCorrect: false,
                          },
                          {
                            name: "",
                            isChecked: false,
                            id: el.id,
                            isCorrect: false,
                          },
                        ],
                      };
                    } else {
                      return q;
                    }
                  } else {
                    if (number === questionIndex) {
                      const { list, ...obj } = q;
                      return {
                        ...obj,
                        type: type,
                        list: [
                          {
                            name: "",
                            id: el.id,
                            answer: "",
                          },
                        ],
                      };
                    } else {
                      return q;
                    }
                  }
                }),
              }
            : el;
        })
      );
    },
    [blockQuestions]
  );
  // функции и состояния, связанные с редактированием типов вопросов

  useEffect(() => {
    // инициализируем значения для массива translate
    setTranslate(() =>
      blockQuestions[index].questions.map((el) => {
        // el = "translateY(-110%)";
        el = "0";
        return el;
      })
    );
  }, [blockQuestions[index].questions.length]);

  // useEffect(() => {
  //   console.log(blockQuestions);
  // }, [blockQuestions]);

  return (
    <section className={styles.questions_section}>
      {blockQuestions[index].questions.map((el, num) => {
        return (
          <div
            style={arrayDragStyle[num]}
            className={styles.question_in_block}
            key={num}
          >
            <div
              // draggable={true}
              onDrag={(e) => {
                if (setBlockQuestions == null) return;
                dragFunc(e, num);
                if (e.nativeEvent.offsetY > 100 || e.nativeEvent.offsetY < -100)
                  // все что начивается отсюда, просто отвечает за поведение др блоков во время перемещения
                  // чисто для визуализации происходящего
                  setArrayDragStyle(
                    arrayDragStyle.map((el, count) => {
                      if (count == num) {
                        return {
                          ...el,
                          boxShadow: "0 0 10px white",
                          opacity: 0.9,
                          transform: `translateY(${
                            e.nativeEvent.offsetY / 2
                          }vh)`,
                          transition: ".5s",
                        };
                      }
                      return el;
                    })
                  );
                if (e.nativeEvent.offsetY > 50)
                  setArrayDragStyle(
                    arrayDragStyle.map((el, count) => {
                      if (count - 1 == num) {
                        return {
                          ...el,
                          transform: `translateY(-${
                            e.nativeEvent.offsetY / 2
                          }vh)`,
                          transition: ".5s",
                        };
                      }
                      return el;
                    })
                  );
                if (e.nativeEvent.offsetY < -50)
                  setArrayDragStyle(
                    arrayDragStyle.map((el, count) => {
                      if (count + 1 == num) {
                        return {
                          ...el,
                          transform: `translateY(${
                            -e.nativeEvent.offsetY / 2
                          }vh)`,
                          transition: ".5s",
                        };
                      }
                      return el;
                    })
                  );
              }}
              onDragEnd={(e) => {
                if (setBlockQuestions == null) return; // для простых смертных после активации сессии редактирование невозможно
                setBlockQuestions((prev) =>
                  prev.map((el, count) => {
                    if (count == index)
                      el = {
                        ...el,
                        questions: moveNew(
                          e.nativeEvent.offsetY,
                          num,
                          el.questions
                        ),
                      };
                    return el;
                  })
                );
              }}
              className={styles.sandwich}
            >
              <img src={require("../../../Images/menu.png")} alt="" />
            </div>
            <div className={styles.question_content}>
              {num > 0 && ( // для первого элемента скрываем удаление
                <span
                  className={styles.delete_question}
                  onClick={() => {
                    const arr = structuredClone(blockQuestions);
                    const newArr = arr.filter((el, i) => {
                      if (i === index) {
                        return (el.questions = arr[index].questions.filter(
                          (q, count) => count != num
                        ));
                      } else {
                        return el;
                      }
                    });
                    if (setBlockQuestions == null) return; // для простых смертных после активации сессии редактирование невозможно
                    setBlockQuestions(newArr);
                  }}
                >
                  ✕
                </span>
              )}
              <div
                className={styles.container_of_types_questions}
                style={{
                  // transform: translate[num],
                  height: translate[num],
                  padding: translate[num] == "0" ? "0" : "20px 40px",
                }}
              >
                <ul>
                  <li
                    style={
                      el.type == "questionsChoiceOfOptionsOne"
                        ? { background: "white", color: "black" }
                        : {}
                    }
                    onClick={() =>
                      changeQuestionType("questionsChoiceOfOptionsOne", num)
                    }
                  >
                    Один вариант ответа
                  </li>
                  <li
                    style={
                      el.type == "questionsChoiceOfOptionsMany"
                        ? { background: "white", color: "black" }
                        : {}
                    }
                    onClick={() =>
                      changeQuestionType("questionsChoiceOfOptionsMany", num)
                    }
                  >
                    Несколько вариантов ответа
                  </li>
                  <li
                    style={
                      el.type == "questionsOnScale"
                        ? { background: "white", color: "black" }
                        : {}
                    }
                    onClick={() => changeQuestionType("questionsOnScale", num)}
                  >
                    Ответ по шкале
                  </li>
                  <li
                    style={
                      el.type == "questionsQuizOne"
                        ? { background: "white", color: "black" }
                        : {}
                    }
                    onClick={() => changeQuestionType("questionsQuizOne", num)} // создание квиза
                  >
                    Квиз (один вариант)
                  </li>
                  <li
                    style={
                      el.type == "questionsQuizMany"
                        ? { background: "white", color: "black" }
                        : {}
                    }
                    onClick={() => changeQuestionType("questionsQuizMany", num)} // создание квиза
                  >
                    Квиз (множественный выбор)
                  </li>
                  <li
                    style={
                      el.type == "questionsQuizFreeText"
                        ? { background: "white", color: "black" }
                        : {}
                    }
                    onClick={() =>
                      changeQuestionType("questionsQuizFreeText", num)
                    } // создание квиза свободный ответ
                  >
                    Квиз (свободный ответ)
                  </li>
                </ul>
              </div>
              <div
                onClick={(e) => {
                  changeTranslate(translate[num] == "0" ? "auto" : "0", num);
                }}
                className={styles.open_types_button}
                title="Выбрать тип вопроса"
              >
                •••
              </div>
              {el.type == "questionsChoiceOfOptionsOne" ||
              el.type == "questionsChoiceOfOptionsMany" ? ( // здесь уточняем тип вопроса и редорим нужный интерфейс
                <CurrentQuestions
                  blockQuestions={blockQuestions}
                  setBlockQuestions={setBlockQuestions}
                  index={index}
                  questionIndex={num}
                />
              ) : el.type == "questionsOnScale" ? (
                <Scale
                  hideBlock={hideBlock}
                  blockQuestions={blockQuestions}
                  setBlockQuestions={setBlockQuestions}
                  index={index}
                  questionIndex={num}
                />
              ) : el.type == "questionsQuizOne" ||
                el.type == "questionsQuizMany" ? (
                <QuizOneOrManyVariant
                  blockQuestions={blockQuestions}
                  setBlockQuestions={setBlockQuestions}
                  index={index}
                  questionIndex={num}
                />
              ) : (
                <QuizFreeText
                  blockQuestions={blockQuestions}
                  setBlockQuestions={setBlockQuestions}
                  index={index}
                  questionIndex={num}
                />
              )}
            </div>
          </div>
        );
      })}
      <div
        className={styles.add_question_button}
        onClick={addQuestionOnCurrentBlock}
      >
        <img src={require("../../../Images/Icon.png")} alt="" /> Добавить вопрос
      </div>
    </section>
  );
};

export default BlockQuestionsComponent;
