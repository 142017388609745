const INITIAL_STATE = "INITIAL_STATE";
const SET_SESSION_ID = "SET_SESSION_ID";
const SET_QUIZ_DATA = 'SET_QUIZ_DATA'

let initialStateQuestions = {
  sessionId: null, // без данного параметра не происходит запроса на сервер в app
  // backgroundImage: 'https://psql-congress.pep-server.ru/backgrounds/default.png',
  // backgroundImage: ' https://api-congress.pointreg.ru/backgrounds/default.png',
  backgroundImage: '',
  session_status: '',
  containerColor: 'rgba(0, 0, 0, 0.85)',
  variantActive: '#00457c',
  buttonsColor: ['#94c11c', 'black'],
  clientTextColor: 'white',
  blockNameColor: 'wheat',
  questionNameColor: 'rgb(151, 152, 255)',
  questions: [],
};

const questionsState = (state = initialStateQuestions, action) => {
  switch (action.type) {
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.id
      };
    case INITIAL_STATE:
      return {
        ...state,
        questions: action.questions,
        backgroundImage: action.backgroundImage,
        session_status: action.session_status,
        containerColor: action.containerColor,
        variantActive: action.variantActive,
        buttonsColor: action.buttonsColor,
        clientTextColor: action.clientTextColor,
        blockNameColor: action.blockNameColor,
        questionNameColor: action.questionNameColor
      };
    case SET_QUIZ_DATA:
      return {
        ...state,
        questions: state.questions.map((el) => {
          if (el.type === 'questionsQuizOne' || el.type === 'questionsQuizMany' || el.type === 'questionsQuizFreeText') {
            for (let i in action.keys) {
              if (action.keys[i].value)
                el[action.keys[i].type] = action.keys[i].value
            }
          }
          return el
        })
      }
    default:
      return state;
  }
};

export const initialStateAction = (questions, backgroundImage, session_status, containerColor, variantActive, buttonsColor, clientTextColor, blockNameColor, questionNameColor) => {
  return { type: INITIAL_STATE, questions, backgroundImage, session_status, containerColor, variantActive, buttonsColor, clientTextColor, blockNameColor, questionNameColor };
};
export const setSessionIdAction = (id) => {
  return { type: SET_SESSION_ID, id };
};
export const setQuizDataAction = (keys) => {
  return { type: SET_QUIZ_DATA, keys };
};

export default questionsState;
