import React, { useState } from "react";
import style from "./freeAnswer.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addFreeAnswerAction } from "../../Redux/AnswersReducer";

const FreeAnswer = ({ currentBlock, currentGlobalQuestionsIndex }) => {
  const [freeAnswerValue, setFreeAnswerValue] = useState("");
  const { answers } = useSelector((state) => state.answers);
  const changeFreeAnswerValue = (e) => {
    dispatch(
      addFreeAnswerAction(currentBlock.blockName, e.currentTarget.value)
    );
  };
  const dispatch = useDispatch();
  return (
    <section>
      <h2 className={style.head}>{currentBlock.blockName}</h2>
      <div className={style.main}>
        <textarea
          name="text"
          rows="15"
          onChange={changeFreeAnswerValue}
          value={answers[currentGlobalQuestionsIndex].answer}
        ></textarea>
      </div>
    </section>
  );
};

export default FreeAnswer;
