import React, { useEffect, useState } from "react";
import styles from "./moderation.module.css";
import {
  deleteQuestion,
  getAllQuestions,
  setIsAnswered,
} from "../../../DAL/dal";

const ApproveQuestions = ({
  openApproveQuestions,
  setOpenApproveQuestions,
  id,
  socket,
  questions,
  setQuestions,
  sortFunction,
}) => {
  const [width, setWidth] = useState([]);

  useEffect(() => {
    setWidth(
      questions.map((el) => {
        el = "0px";
        return el;
      })
    );
  }, [questions]);

  const mouseIn = (index) => {
    if (!questions[index].isAnswered)
      setWidth((prev) =>
        prev.map((el, count) => {
          if (index == count) el = "150px";
          return el;
        })
      );
  };
  const mouseLeave = (index) => {
    if (!questions[index].isAnswered)
      setWidth((prev) =>
        prev.map((el, count) => {
          if (index == count) el = "0px";
          return el;
        })
      );
  };

  const changeIsAnswered = (id, key) => {
    setIsAnswered(id, key).then((r) => {
      setQuestions(sortFunction(r));
      socket.emit("deleteOrUpdateQuestion", { r, id });
    });
  };

  return (
    <div
      style={{ width: openApproveQuestions }}
      className={styles.approve_container}
    >
      <div
        onClick={() => setOpenApproveQuestions("0vw")}
        style={{ color: "white", cursor: "pointer", padding: "20px" }}
      >
        ✕
      </div>
      <div className={styles.approve_questions_container}>
        {questions.map((el, index) => {
          const deleteFunc = () => {
            deleteQuestion(id, el.key).then((r) => {
              setQuestions(r.sort((a, b) => b.range - a.range));
              socket.emit("deleteOrUpdateQuestion", { r, id });
            });
          };
          return (
            <div
              style={
                openApproveQuestions == "0vw"
                  ? { opacity: 0 }
                  : {
                      opacity: 1,
                      transitionDelay: ".5s",
                      background: index % 2 ? "#3d3d3d88" : "none",
                    }
              }
              key={index}
              className={styles.current_approve_question}
              onMouseEnter={() => mouseIn(index)}
              onMouseLeave={() => mouseLeave(index)}
            >
              <section
                onClick={() => changeIsAnswered(id, el.key)}
                className={styles.set_isanswered}
                style={{
                  minWidth: width[index],
                }}
              >
                Пометить отвеченным
              </section>
              <div style={el.isAnswered ? { opacity: 0.2 } : {}}>
                {el.question}
              </div>
              <div
                style={
                  width[index] == "150px"
                    ? {
                        transform: "translate(-150px, -50px) scale(1.3)",
                        opacity: 1,
                        textShadow: "0 0 2px red",
                      }
                    : {}
                }
                onClick={deleteFunc}
              >
                ✕
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ApproveQuestions;
