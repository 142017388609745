import React, { useEffect, useMemo, useState } from "react";
import style from "./MainResults.module.css";
import { useSelector } from "react-redux";

const VizualResultContainer = ({
  result,
  containerColor,
  blockNameColor,
  questionNameColor,
  clientTextColor,
  reRender,
  setForGetCurrentResult,
  forGetCurrentResult,
}) => {
  const { questions } = useSelector((state) => state.questions);
  const [arrayCounts, setArrayCounts] = useState([]);
  const [simbols, setSimbols] = useState(0); // колличество приходящих букв всех вариантов когда приходят слишком объемные варианты
  const [variantRows, setVariantRows] = useState(0); // колличество вариантов для уменьшения отступов и шрифтов и всего остального

  const [interval, setInterval] = useState(null);

  useEffect(() => {
    const id = result[0]?.list[0].id;
    if (result.length > 1 && result.find((r) => r.list[0].id != id)) {
      setInterval(clearTimeout(interval));
      // console.log("выполняем");
      setInterval(
        setTimeout(() => {
          setForGetCurrentResult(!forGetCurrentResult);
          // console.log("запрос");
        }, 500)
      );
    }
  }, [result]);

  const size_question_title = useMemo(
    // то что отвечает за адаптив по кол-ву символов
    () =>
      simbols > 1300 && simbols <= 1400
        ? 60000
        : simbols > 1400
        ? 70000
        : 50000,
    [simbols]
  );
  const size_variants_name = useMemo(
    () =>
      simbols > 1300 && simbols <= 1400
        ? 28000
        : simbols > 1400
        ? 33000
        : 22000,
    [simbols]
  );
  const image = useMemo(() => {
    return (
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="42.000000pt"
        height="40.000000pt"
        viewBox="0 0 1280.000000 1216.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,1216.000000) scale(0.100000,-0.100000)"
          //  fill={
          //    number <= answers[currentGlobalQuestionsIndex].range.choose
          //      ? "#00457c"
          //      : "#fff"
          //  }
          fill={clientTextColor}
          stroke="none"
        >
          <path
            d="M5890 10598 c-332 -755 -736 -1674 -898 -2043 -161 -368 -295 -671
   -297 -673 -2 -2 -308 -25 -682 -52 -373 -27 -1054 -76 -1513 -109 -459 -34
   -1087 -79 -1395 -101 -308 -22 -585 -43 -615 -46 l-54 -6 49 -47 c28 -25 336
   -300 684 -611 349 -311 806 -718 1016 -905 1267 -1130 1560 -1391 1572 -1400
   17 -13 74 228 -542 -2265 -256 -1036 -464 -1887 -463 -1890 2 -4 869 499 1928
   1117 1058 618 1931 1122 1940 1120 8 -2 398 -242 865 -532 468 -291 1165 -724
   1550 -963 385 -239 811 -504 947 -588 135 -85 249 -154 253 -154 4 0 4 17 0
   38 -6 34 -411 1897 -776 3568 -87 402 -159 738 -159 747 0 13 649 563 2997
   2542 258 217 261 220 230 227 -18 4 -1011 104 -2207 223 -1196 119 -2184 220
   -2196 225 -15 6 -62 111 -199 446 -98 242 -412 1013 -697 1714 -285 701 -564
   1388 -620 1525 -56 138 -104 253 -108 258 -3 4 -278 -610 -610 -1365z"
          />
        </g>
      </svg>
    );
  }, [result, reRender]);

  // проверяем есть ли range в пришедших результатах
  const [scale, setScale] = useState([
    // {
    //   arrayForMapStars: [],
    //   middleRange: 0,
    //   count: 0,
    //   result() {
    //     if (this.middleRange)
    //       return (
    //         (this.middleRange / this.count / this.arrayForMapStars.length) * 100
    //       );
    //   },
    // },
  ]); // массив объектов для отображение звездочек

  useEffect(() => {
    const arr = [];
    for (let i in questions) {
      if (questions[i].type == "questionsOnScale") {
        arr.push({
          arrayForMapStars: Array.apply(
            null,
            Array(questions[i]?.range?.range)
          ),
          middleRange: 0,
          count: 0,
          result() {
            if (this.middleRange)
              return (
                (this.middleRange / this.count / this.arrayForMapStars.length) *
                100
              );
          },
          key: arr.length,
        });
      } else arr.push("");
    }
    setScale(arr); // базово создаем массив с объектами звезд
  }, [result, questions]);

  useEffect(() => {
    for (let i in result) {
      for (let j in result[i].list) {
        if (result[i]?.list[j]?.range) {
          setScale((prev) =>
            prev.map((el, count) => {
              return count == j && el
                ? {
                    ...el,
                    middleRange: (el.middleRange += result[i]?.list[j]?.range), // здесь уже работаем непосредственно с приходящим результатом
                    count: (el.count += 1),
                  }
                : el;
            })
          );
        }
      }
    }
  }, [result, questions]);
  useEffect(() => {
    let middleArray = []; // так как useState асинхронная история, просто создаем промежуточную переменную
    setSimbols(0);
    setVariantRows(0);
    setArrayCounts([]);
    for (let i = 0; i < questions.length; i++) {
      if (
        questions[i].type == "questionsChoiceOfOptionsOne" ||
        questions[i].type == "questionsChoiceOfOptionsMany"
      ) {
        for (let j = 0; j < questions[i].list.length; j++) {
          // рендерим все варианты
          setSimbols(
            (simbols) => (simbols += questions[i]?.list[j].name.length)
          ); // считаем кол во всех символов
          setVariantRows((prev) => prev + 1); // считаем колличество пришедших вариантов
          middleArray = [
            ...middleArray,
            { name: questions[i].list[j].name, count: 0, key: i },
          ];
        }
      }
    }
    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < middleArray.length; j++) {
        for (let k = 0; k < result[i].list?.length; k++) {
          if (
            result[i].list[k].name === middleArray[j].name &&
            result[i].list[k]?.key === middleArray[j].key
          ) {
            middleArray = [
              ...middleArray.map((counter, num) =>
                j === num ? { ...counter, count: counter.count + 1 } : counter
              ),
            ].sort((a, b) => b.count - a.count);
          }
        }
      }
    }

    if (middleArray.length) setArrayCounts(middleArray); // здесь из за множества rerender массив может оказаться пустым
  }, [result, questions, reRender, variantRows]);

  return (
    <>
      <section
        style={{ backgroundColor: containerColor }}
        className={style.vizualContainer}
      >
        <h1
          style={{
            color: blockNameColor,
            fontSize:
              simbols > 950
                ? `${size_question_title / (simbols / 1.3)}px`
                : variantRows > 9 && simbols <= 950
                ? "44px"
                : "48px",
            fontWeight: 900,
          }}
          className={style.headCase}
        >
          Результаты голосования
        </h1>
        {questions.map((el, index) => {
          if (
            el.type == "questionsQuizOne" ||
            el.type == "questionsQuizMany" ||
            el.type == "questionsQuizFreeText"
          )
            // находим квиз и не отрисовываем результаты
            return (
              <div className={style.question_quiz} key={index}>
                Вопрос квиз!
                <img src={require("../../Images/quiz.png")} />
                <div>Итоги будут подведены в конце</div>
              </div>
            );
          return (
            <div key={index}>
              <h2
                style={{
                  color: questionNameColor,
                  fontSize:
                    simbols > 950
                      ? `${Math.floor(size_question_title / simbols)}px`
                      : variantRows > 9 && simbols <= 950
                      ? "40px"
                      : "44px",
                  fontWeight: 900,
                  marginBottom: variantRows < 9 ? "3vh" : "0.5vh",
                }}
              >
                {el.blockName}
              </h2>
              <div>
                {el.range ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                      gap: "20px",
                    }}
                  >
                    <div className={style.for_stars}>
                      {scale[index]?.arrayForMapStars?.map(
                        (
                          el,
                          index // для того чтобы придать объем родительскому контейнеру, соответствующий кол-ву звезд
                        ) => (
                          <span style={{ opacity: 0.1 }} key={index}>
                            {image}
                          </span>
                        )
                      )}

                      {scale[index]?.count ? (
                        <div
                          className={style.line_stars}
                          style={{ width: `${scale[index].result()}%` }}
                        >
                          {el.range
                            ? scale[index].arrayForMapStars.map((el, index) => (
                                <span key={index}>{image}</span>
                              ))
                            : null}
                        </div>
                      ) : null}
                    </div>
                    <span style={{ height: "max-content", fontSize: "24px" }}>
                      {scale[index]?.count ? (
                        <>
                          средняя оценка:{" "}
                          {(
                            (scale[index].result() / 100) *
                            Number(scale[index].arrayForMapStars.length)
                          )?.toFixed(1)}
                        </>
                      ) : null}
                    </span>
                  </div>
                ) : null}

                {arrayCounts.map((li, n) => {
                  if (index === li.key)
                    return (
                      <div
                        key={n}
                        className={style.variants_and_result_container}
                      >
                        <div
                          style={{
                            color: clientTextColor,
                            fontSize:
                              simbols > 950
                                ? `${size_variants_name / simbols}px`
                                : variantRows > 9 && simbols <= 950
                                ? "28px"
                                : "34px",
                          }}
                          className={style.resultVariant}
                        >
                          {li.name}
                        </div>
                        <div
                          style={{
                            opacity: li.count ? 1 : 0,
                            width: arrayCounts.find((el) => el.name.length > 60)
                              ? "32vw"
                              : "55vw",
                            fontSize:
                              simbols > 950
                                ? `${size_variants_name / (simbols / 1.7)}px`
                                : variantRows > 9 && simbols <= 950
                                ? "38px"
                                : "44px",
                          }}
                          className={style.current_results}
                        >
                          <span>
                            {li.count} /
                            {((li.count * 100) / result.length).toFixed(1)}%
                          </span>
                          <div
                            style={{
                              background: "#94949481",
                              transition: "1s",
                              width: `${(
                                (li.count * 100) /
                                result.length
                              ).toFixed(1)}%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    );
                })}
              </div>
            </div>
          );
        })}
      </section>
    </>
  );
};

export default VizualResultContainer;
