import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./Section.module.css"; // отделые стили для компоненты выбранной сессии
import { useNavigate, useParams } from "react-router-dom";
import {
  activeSessionStatus,
  addBackgroundFone,
  addQuizBet,
  changeSessionColors,
  findAskForSession,
  getOneSession,
  getUserWithOutToken,
  setSesseonDescription,
  updateQuestionsDate,
  updateSessionName,
} from "../../../DAL/dal";
import { CompileQuesions } from "../CompiledCaseDir/CompileCase";
import StylesCase from "../StylesCase";
import {
  ActivitySession,
  AlertModal,
  DeleteSession,
  EditBlockName,
} from "../HtmlElements/Elements";
import { QRCodeCanvas } from "qrcode.react";
import { form } from "../../Utils/utils";
import TelegramInterface from "./Telegram/TelegramInterface";
const shortid = require("shortid");

const SessionComponent = ({
  reRender,
  setRerender,
  role,
  setTotalSessions,
  setAllSessions,
  editModeType,
  setEditModeType,
  setEditMode,
  editMode,
  questionObject,
  tariff,
  email,
  shortid,
  height,
}) => {
  const ref = useRef();

  const { id } = useParams();
  const [indexForEdit, setIndexForEdit] = useState(0); // индек который мы передаем в компоненту для редактирования
  const navigate = useNavigate();
  const [currentSession, setCurrentSession] = useState({});
  const [fileData, setFileData] = useState(null);
  const [description, setDescription] = useState("");
  const [isSetDescription, setIsSetDescription] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionsClone, setQuestionsClone] = useState([]); // для сравнения объектов и вывода кнопки сохранить изменения
  const [infoForAlert, setInfoForAlert] = useState(
    "Больше 10-ти вопросов в блоке допустимо только в рамках квиза"
  ); // состояние, которое хранит информацию для AlertModal
  const [openQR, setOpenQR] = useState(false); // показывает QR код
  const [previewImage, setPreviewImage] = useState(
    "https://api-congress.pointreg.ru/backgrounds/default.png"
  );
  const [onLoad, setOnLoad] = useState(false);

  const [owner, setOwner] = useState("");
  const [colors, setColors] = useState({
    clientTextColor: "#000000",
    containerColor: "#000000",
    variantActive: "#000000",
    blockNameColor: "#000000",
    questionNameColor: "#000000",
    buttonsColor: ["#000000", "#000000"],
  });
  const [colorsClone, setColorsClone] = useState({}); // для сравнения объектов и вывода кнопки сохранить изменения

  const [quizForm, setQuizForm] = useState({}); // тип формы для квиза, если есть квиз
  const [quizFormClone, setQuizFormClone] = useState({}); // клон для сравнения
  const [betQuiz, setBetQuiz] = useState(false); // клон для сравнения
  const [isActive, setIsActive] = useState(false);

  const openQRFunction = () => setOpenQR(!openQR);

  const changeTextAreaDescription = (e) => {
    setIsSetDescription(true);
    setDescription(e.target.value);
  };

  function alphabetically() {
    // функция которая отвечает за то чтобы квизовый блок был всегда в конца массива если он есть)
    return function (a, b) {
      if (a === b) {
        return 0;
      }
      if (a.caseName === "Блок результатов квиза") {
        return 1;
      }
      if (b.caseName === "Блок результатов квиза") {
        return -1;
      }
      return a < b ? -1 : 1;
    };
  }
  // console.log(questions);

  const key = useMemo(() => shortid.generate(), [questions]);
  const initCaseNameState = useCallback(() => {
    // нумерует блоки вопросов
    setQuestions((prev) =>
      prev.map((element, index) => {
        // console.log(element);
        if (element.caseName == "Блок вопросов") {
          // const key = shortid.generate();
          element = {
            caseName: `Блок вопросов ${index + 1}`,
            id: key,
            questions: [
              {
                blockName: "",
                type: "questionsChoiceOfOptionsOne",
                list: [
                  { name: "", isChecked: false, id: key },
                  { name: "", isChecked: false, id: key },
                ],
              },
            ],
          };
        }
        return element;
      })
    );
  }, [questions]);

  const addNewBlock = () => {
    const arr = structuredClone([...questions, questionObject]);
    setQuestions(arr.sort(alphabetically())); // следим за тем, чтобы пустой квизовый блок всегда был в конце
    initCaseNameState();
  };
  // console.log(questions)
  const addNewCopyBlockForEdit = () => {
    const editQuestions = structuredClone(questions);
    const arr = structuredClone([
      ...questions,
      questions[questions.length - 1].caseName == "Блок результатов квиза"
        ? editQuestions[questions.length - 2]
        : editQuestions[questions.length - 1],
    ]);
    setQuestions(arr.sort(alphabetically())); // следим за тем, чтобы пустой квизовый блок всегда был в конце
    // initCaseNameState();
  };

  useEffect(() => {
    getOneSession(id).then((r) => {
      if (r.data.description) setDescription(r.data.description);
      setCurrentSession(r.data);
      setQuestions(r.data.questionsdata);
      setQuestionsClone(r.data.questionsdata);
      setPreviewImage(r.data.backgroundimage);
      setBetQuiz(r.data.bet_quiz);
      const resQuestions = r.data.questionsdata; // для удобства работы сохраняем вопросы с сервера в переменную
      for (let i in resQuestions) {
        if (resQuestions[i].caseName === "Блок результатов квиза") continue; // нет смысла проверять этот блок
        for (let j in resQuestions[i].questions) {
          if (
            resQuestions[i].questions[j].type === "questionsQuizOne" ||
            resQuestions[i].questions[j].type === "questionsQuizMany" ||
            resQuestions[i].questions[j].type === "questionsQuizFreeText"
          ) {
            // если встречаем квиз
            const { blockName, list, type, ...data } =
              resQuestions[i].questions[j];
            // console.log(data);
            setQuizForm(data); // находим первый квиз, забираем форму и останавливаем цикл
            setQuizFormClone(data); // наполняем клон для дальнейшего сравнения
            break;
          }
        }
      }
      const obj = {
        // для того чтобы избежать дублирования деструктуризации при сете в основной state и в clone
        clientTextColor: r.data.clienttextcolor,
        containerColor: r.data.containercolor,
        variantActive: r.data.variantactive,
        blockNameColor: r.data.blocknamecolor,
        questionNameColor: r.data.questionnamecolor,
        buttonsColor: r.data.buttonscolor,
      };
      setColors(obj);
      setColorsClone(obj);
    });
  }, [reRender]);

  // блок отвечающий за появление кнопки сохранения вопросов

  const [isNeedUpdateQuestions, setIsNeedUpdateQuestions] = useState(false);

  useEffect(() => {
    setIsNeedUpdateQuestions(false);
    if (questions.length != questionsClone.length)
      // на этом этапе проверяем длину массива всех блоков и сразу возвращаем чтобы не идти дальше по коду
      return setIsNeedUpdateQuestions(true);
    for (let i in questions) {
      if (questions[i].questions.length != questionsClone[i].questions.length)
        return setIsNeedUpdateQuestions(true);
      if (questions[i].caseName != questionsClone[i]?.caseName)
        return setIsNeedUpdateQuestions(true);
      for (let j in questions[i].questions) {
        if (
          questions[i].questions[j].blockName !=
          questionsClone[i]?.questions[j]?.blockName
        )
          return setIsNeedUpdateQuestions(true);
        if (questions[i].questions[j]?.range) {
          if (
            questions[i].questions[j]?.range.range !=
            questionsClone[i]?.questions[j]?.range?.range
          )
            return setIsNeedUpdateQuestions(true);
        }
        if (
          questions[i].questions[j]?.list?.length !=
          questionsClone[i]?.questions[j]?.list?.length
        )
          return setIsNeedUpdateQuestions(true);
        if (
          questions[i].questions[j].type !=
          questionsClone[i]?.questions[j]?.type
        )
          return setIsNeedUpdateQuestions(true);
        for (let k in questions[i].questions[j]?.list) {
          if (
            questions[i].questions[j]?.list[k]?.name !=
              questionsClone[i].questions[j]?.list[k]?.name ||
            questions[i].questions[j]?.list[k]?.isCorrect !=
              questionsClone[i].questions[j]?.list[k]?.isCorrect
          )
            return setIsNeedUpdateQuestions(true);
        }
      }
    }
    const arrayFirst = Object.keys(quizForm);
    const arraySecond = Object.keys(quizFormClone);
    // здесь проверяем форму для квиза при условии что она существует
    if (arrayFirst.length) {
      if (arrayFirst.length != arraySecond.length)
        return setIsNeedUpdateQuestions(true);
      else {
        for (let i in arrayFirst) {
          if (arrayFirst[i] != arraySecond[i])
            return setIsNeedUpdateQuestions(true);
        }
      }
    }
  }, [questions, quizForm]);

  // блок отвечающий за появление кнопки сохранения вопросов

  useEffect(() => {
    if (role === "admin" && currentSession.user_id != undefined) {
      getUserWithOutToken(currentSession.user_id).then((r) =>
        setOwner(r.data.email)
      );
    }
  }, [currentSession]);

  useEffect(() => {
    if (currentSession.session_status == "активна") setIsActive(true);
  }, [currentSession]);
  const linkCopy = (e) => {
    const style = () => {
      // меняем стили для визуала
      e.target.style.transform = "scale(1.3) rotate(360deg)";
      e.target.style.transition = ".6s";
      setTimeout(() => {
        e.target.style.transform = "scale(1) rotate(0)";
      }, 500);
    };
    switch (e.target.alt) {
      case "QR":
        navigator.clipboard.writeText(`${window.location.origin + "/#/" + id}`);
        style();
        break;
      case "result":
        navigator.clipboard.writeText(
          `${window.location.origin + "/#/result/" + id}`
        );
        style();
        break;
      case "cases":
        navigator.clipboard.writeText(
          `${window.location.origin + "/#/cases/" + id}`
        );
        style();
        break;
      default:
        break;
    }
  };

  const changeSessionName = (text) => {
    setCurrentSession((state) => ({ ...state, sessionname: text }));
    updateSessionName(text, id).then((r) => setEditModeType(""));
  };

  const [isAsk, setIsAsk] = useState(false);
  useEffect(() => {
    if (currentSession.sessionid)
      findAskForSession(currentSession.sessionid).then((r) =>
        r ? setIsAsk(true) : null
      );
  }, [currentSession]);

  const element = useMemo(
    () =>
      editModeType == "delete_session" ? (
        <DeleteSession
          sessionName={currentSession.sessionname}
          setAllSessions={setAllSessions}
          id={currentSession.sessionid}
          setEditMode={setEditMode}
          setEditModeType={setEditModeType}
        /> // компонента удаления сессии
      ) : editModeType == "session_name" ? (
        <EditBlockName
          state={currentSession.sessionname}
          setEditMode={setEditMode}
          index={0}
          setState={changeSessionName}
          setEditModeType={setEditModeType}
        />
      ) : editModeType == "alert" ? (
        <AlertModal
          info={infoForAlert}
          setEditMode={setEditMode}
          setEditModeType={setEditModeType}
        />
      ) : editModeType == "activity_session" ? (
        <ActivitySession
          setEditMode={setEditMode}
          setEditModeType={setEditModeType}
          id={currentSession.sessionid}
          isActive={isActive} // перемещает ползунок
          setIsActive={setIsActive} // перемещает ползунок
          setRerender={setRerender} // перерисовывает для отображения активации
        />
      ) : (
        <CompileQuesions
          alertInfo={infoForAlert}
          setAlertInfo={setInfoForAlert}
          setIsAsk={setIsAsk}
          isAsk={isAsk}
          indexForEdit={indexForEdit}
          setIndexForEdit={setIndexForEdit}
          setEditModeType={setEditModeType}
          editModeType={editModeType}
          blockQuestions={questions}
          currentSessionId={id}
          setBlockQuestions={
            role == "admin" || // если роль админ, то всегда можем редактировать
            !currentSession.ended_time || // можно редактировать если сессия не активна, значит это поле будет пустым
            currentSession.ended_time > new Date().getTime() // или если время не вышло
              ? setQuestions
              : null
          }
          // currentSession.session_status != "активна"
          setEditMode={setEditMode}
          editMode={editMode}
          role={role}
          questionObject={questionObject}
          addNewBlockForEdit={addNewBlock}
          addNewCopyBlockForEdit={addNewCopyBlockForEdit}
          session_status={currentSession.session_status}
        />
      ),
    [editMode, questions, editModeType, isAsk]
  );

  const [openQuizForm, setOpenQuizForm] = useState(false);

  return (
    <>
      {editMode ? (
        <>{element}</> // такое решение чтобы была возможность переиспользовать компоненту
      ) : (
        <section className={styles.current_session_container}>
          <div className={styles.back_and_delete_session}>
            <div>
              <span
                onClick={() => {
                  navigate("/admin");
                }}
              >
                ←
              </span>
              <span>Вернуться к списку сессий</span>
            </div>
            <div
              onClick={() => {
                setEditModeType("delete_session");
                setEditMode(true);
              }}
            >
              ✕
            </div>
          </div>
          <div className={styles.title_container}>
            <h1 className={styles.session_title}>
              {currentSession.sessionname}
              <img
                onClick={() => {
                  setEditModeType("session_name");
                  setEditMode(true);
                }}
                src={require("../../../Images/Button_opacity.png")}
                alt=""
              />
            </h1>
          </div>
          <div className={styles.active_session_owner_and_id}>
            <div
              style={isActive ? { backgroundColor: "rgb(76, 250, 76)" } : {}}
              onClick={() => {
                if (role == "admin")
                  activeSessionStatus(id).then((r) => {
                    setTotalSessions(null);
                    setIsActive(!isActive);
                  });
                else if (
                  role == "user" &&
                  currentSession.session_status == "не активна" &&
                  Number(tariff?.sessions) > 0 &&
                  new Date(tariff?.end_time).getTime() > new Date().getTime() // здесь мы проверяем что тариф пользователя позволяет активировать сессию
                ) {
                  setTotalSessions(null);
                  setEditModeType("activity_session");
                  setEditMode(true);
                } else if (currentSession.session_status == "активна") {
                  return;
                } else {
                  setEditModeType("alert");
                  setInfoForAlert("У Вас нет возможности активировать сессию");
                  setEditMode(true);
                }
              }}
            >
              <div style={!isActive ? { marginInlineStart: 0 } : {}}></div>
            </div>
            <div>{isActive ? "Сессия активна" : "Сессия не активна"}</div>
            <div>Создатель: {role == "admin" ? owner : email}</div>
            <div>
              {currentSession.sessionid}
              <img
                onClick={(e) => {
                  navigator.clipboard
                    .writeText(currentSession.sessionid)
                    .then((r) => {});
                }}
                src={require("../../../Images/copy-session-id.png")}
                alt=""
              />
            </div>
          </div>
          <div className={styles.links}>
            <div>
              <img
                onClick={linkCopy}
                src={require("../../../Images/grid.png")}
                alt="QR"
              />
              <span>
                Скопировать ссылку на{" "}
                <span
                  onClick={openQRFunction}
                  style={{
                    color: "rgb(50, 50, 255)",
                    borderBottom: "1px solid rgb(50, 50, 255)",
                    cursor: "pointer",
                  }}
                >
                  QR-код
                </span>
              </span>
              {openQR ? (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    left: 0,
                    margin: "0 auto",
                    top: "30vh",
                    width: "max-content",
                    zIndex: 5,
                    padding: 0,
                  }}
                >
                  <span
                    onClick={openQRFunction}
                    style={{
                      display: "inline-block",
                      margin: "0 0 0 auto",
                      cursor: "pointer",
                    }}
                  >
                    ✕
                  </span>
                  <QRCodeCanvas
                    size={500}
                    value={`${window.location.origin + "/#/" + id}`}
                  />
                </div>
              ) : null}
            </div>
            <div>
              <img
                onClick={linkCopy}
                src={require("../../../Images/pie-chart.png")}
                alt="result"
              />
              <span>Скопировать ссылку на окно результатов</span>
            </div>
            <div>
              <img
                onClick={linkCopy}
                src={require("../../../Images/layout.png")}
                alt="cases"
              />
              <span>Скопировать ссылку на панель управления</span>
            </div>
          </div>

          <div className={styles.notes}>
            <h2>Заметки</h2>
            <textarea
              value={description}
              onChange={changeTextAreaDescription}
            ></textarea>
            {isSetDescription && (
              <button
                onClick={() => {
                  setSesseonDescription(id, description)
                    .then((r) => {
                      setEditModeType("alert");
                      setInfoForAlert("Успешно сохранено!");
                      setEditMode(true);
                      setIsSetDescription(false);
                    })
                    .catch((e) => console.log(e));
                }}
              >
                Сохранить
              </button>
            )}
          </div>
          {role === "admin" ? (
            <TelegramInterface
              setEditMode={setEditMode}
              setInfoForAlert={setInfoForAlert}
              id={id}
              fastId={currentSession.id}
            />
          ) : null}
          <div className={styles.questions_and_styles}>
            <div>
              {questions.find((el) =>
                el.questions.find((el) => el.type === "questionsQuizOne")
              ) ||
              questions.find((el) =>
                el.questions.find((el) => el.type === "questionsQuizMany")
              ) ||
              questions.find((el) =>
                el.questions.find((el) => el.type === "questionsQuizFreeText")
              ) ? (
                <div className={styles.quiz_form_container}>
                  <h2>{"Форма квиза".toLocaleUpperCase()}</h2>
                  <span onClick={() => setOpenQuizForm(!openQuizForm)}>
                    <img
                      style={{ maxWidth: "50px" }}
                      src={require("../../../Images/edit.png")}
                      title="Редактирование формы перс. данных"
                    />
                  </span>
                  <div
                    onClick={() => {
                      addQuizBet(!betQuiz, currentSession.sessionid).then(
                        (r) => {
                          setBetQuiz(!betQuiz);
                        }
                      );
                    }}
                    className={styles.for_quiz_bet}
                    style={{ background: betQuiz ? "rgb(76, 250, 76)" : "" }}
                  >
                    <div style={{ marginLeft: betQuiz ? "50%" : 0 }}></div>
                  </div>
                  <h3 className={styles.bet_quiz_h3}>
                    Вопросы со ставкой
                    <span title="Позволяет пользователям делать ставку на тот или иной вопрос чтобы увеличить количество баллов, получаемых в случае верного ответа или в той же степени, отрицательно повлиять на общий результат в случае неверного ответа">
                      ?
                    </span>
                  </h3>
                  <div
                    style={{
                      height: openQuizForm
                        ? `${ref.current?.clientHeight * 9.5}px`
                        : "0",
                      padding: !openQuizForm ? "0" : "",
                    }}
                    className={styles.form_body}
                  >
                    {form.map((el, index) => {
                      // форма необходимых перс данных для квиза
                      let check = false;
                      for (let key in quizForm) {
                        if (el.value === key) check = true;
                      }
                      return (
                        <div key={index} ref={ref}>
                          <div
                            style={{
                              color: check
                                ? "white"
                                : "rgba(255, 255, 255, 0.3)",
                            }}
                            className={styles.form_value}
                            onClick={() => {
                              // if (
                              //   quizForm["Команда"] == "" &&
                              //   el.value != "Команда"
                              // ) {
                              //   setQuizForm({ Команда: "" });
                              //   setEditModeType("alert");
                              //   setInfoForAlert(
                              //     "Поле Команда не может сочетаться с другими полями!"
                              //   );
                              //   return setEditMode(true);
                              // }   потом надо дописать эту логику, типа нельзя выбрать команду одновременно с др полями и наоборот
                              if (check) {
                                const data = structuredClone(quizForm);
                                delete data[el.value];
                                setQuizForm(data);
                              } else
                                setQuizForm((state) => ({
                                  ...state,
                                  [el.value]: "",
                                }));
                            }}
                          >
                            {el.value}
                            <span
                              style={{
                                transform: check
                                  ? "translateX(-110%)"
                                  : "translateX(110%)",
                              }}
                            >
                              ✓
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {element}
              {isNeedUpdateQuestions ? (
                <button
                  onClick={() => {
                    setOnLoad(true);
                    const middleQuestions = structuredClone(questions).filter(
                      // добавляет новые поля формы
                      (prev) =>
                        prev.questions
                          .map((el) => {
                            for (let key in el) {
                              // перед этим зачищаем все старые
                              if (
                                key === "blockName" ||
                                key === "type" ||
                                key === "list" ||
                                key === "range" ||
                                key === "answer"
                              )
                                continue;
                              else delete el[key];
                            }
                            return el;
                          })
                          .map((el) => {
                            // затем мапим актуальные
                            if (
                              el.type == "questionsQuizOne" ||
                              el.type == "questionsQuizMany" ||
                              el.type == "questionsQuizFreeText"
                            ) {
                              for (let key in quizForm) {
                                el[key] = "";
                              }
                            }
                            return el;
                          })
                    );
                    if (
                      (middleQuestions.find((el) =>
                        el.questions.find((q) => q.type === "questionsQuizOne")
                      ) &&
                        !Object.keys(quizForm).length) ||
                      (middleQuestions.find((el) =>
                        el.questions.find((q) => q.type === "questionsQuizMany")
                      ) &&
                        !Object.keys(quizForm).length) ||
                      (middleQuestions.find((el) =>
                        el.questions.find(
                          (q) => q.type === "questionsQuizFreeText"
                        )
                      ) &&
                        !Object.keys(quizForm).length)
                    ) {
                      setEditModeType("alert");
                      setInfoForAlert("Настройте поля формы!");
                      setOnLoad(false);
                      return setEditMode(true);
                    }

                    const resultQuestions = middleQuestions.map((el) => {
                      const id = shortid.generate();
                      el.id = id;
                      for (let i in el.questions) {
                        if (el.questions[i].range)
                          el.questions[i].range.id = id;
                        else
                          for (let j in el.questions[i].list) {
                            el.questions[i].list[j].id = id;
                          }
                      }
                      return el;
                    });
                    updateQuestionsDate(
                      resultQuestions,
                      currentSession.sessionid
                    ).then((r) => {
                      setQuestions(resultQuestions);
                      setQuestionsClone(resultQuestions);
                      setEditModeType("alert");
                      setInfoForAlert("Вопросы обновлены!");
                      setEditMode(true);
                      setOnLoad(false);
                    });
                  }}
                  className={styles.save_colors_or_image}
                >
                  {onLoad ? (
                    <>
                      <img
                        style={{ width: "30px" }}
                        src={require("../../../Images/89.gif")}
                      />
                    </>
                  ) : null}
                  Сохранить
                </button>
              ) : (
                <></>
              )}
            </div>
            <div className={styles.style_case}>
              <h1>Оформление</h1>
              <StylesCase
                setFileData={setFileData}
                colors={colors}
                setColors={setColors}
                previewImage={previewImage}
                setPreviewImage={setPreviewImage}
                blockQuestions={questions}
                height={height}
              />
              {(fileData || colorsClone != colors) && (
                <button
                  onClick={() => {
                    setOnLoad(true);
                    changeSessionColors(colors, id).then((r) => {
                      // функция обновления сессии
                      if (fileData) {
                        addBackgroundFone(fileData, id).then((res) => {
                          const randome = Math.floor(Math.random() * 10000);
                          setRerender(randome);
                          setEditModeType("alert");
                          setInfoForAlert("Фон и цвета обновлены!");
                          setEditMode(true);
                          setFileData(null);
                          setOnLoad(false);
                        });
                      } else {
                        const randome = Math.floor(Math.random() * 10000);
                        setRerender(randome);
                        setEditModeType("alert");
                        setInfoForAlert("Цвета обновлены!");
                        setEditMode(true);
                        setOnLoad(false);
                      }
                    });
                  }}
                  className={styles.save_colors_or_image}
                >
                  {onLoad ? (
                    <>
                      <img
                        style={{ width: "30px" }}
                        src={require("../../../Images/89.gif")}
                      />
                    </>
                  ) : null}{" "}
                  Сохранить изменения
                </button>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SessionComponent;
