import { modalTypes } from "../types";

const INIT_ANSWER_STATE = "INIT_ANSWER_STATE";
const CHANGE_SCALE = "CHANGE_SCALE";
const CHECKED_VARIANTS = "CHECKED_VARIANTS";
const CHECKED_VARIANT = "CHECKED_VARIANT";
const CHECKED_DONT_SHOW_VARIANT = "CHECKED_DONT_SHOW_VARIANT";
const ADD_FREE_ANSWER = "ADD_FREE_ANSWER";
const ADD_FREE_ANSWER_OTHER_VARIANT = "ADD_FREE_ANSWER_OTHER_VARIANT";
const ADD_FREE_ANSWER_QUIZ = "ADD_FREE_ANSWER_QUIZ";
const PUSH_DONT_SHOW = "PUSH_DONT_SHOW";
const SET_GIFT = "SET_GIFT";
const SET_PERSONAL_DATA = "SET_PERSONAL_DATA";
const SET_BET = 'SET_BET'

let initialStateAnswers = {
  answers: [
    // {
    //   blockName: "Как называется наша планета?",
    //   type: modalTypes.questionsChoiceOfOptionsOne,
    //   list: [
    //     { name: "Земля", isChecked: true },
    //     {
    //       name: "р-321",
    //       isChecked: false,
    //     },
    //     { name: "Солнце", isChecked: false },
    //     { name: "Нептун", isChecked: false },
    //   ],
    // },
    // {
    //   blockName: "Кто первый полетел в космос?",
    //   type: modalTypes.questionsChoiceOfOptionsOne,
    //   list: [
    //     { name: "Гагарин", isChecked: true },
    //     {
    //       name: "Гитлер",
    //       isChecked: false,
    //     },
    //     {
    //       name: "Армстронг",
    //       isChecked: false,
    //     },
    //     {
    //       name: "Чебурашка",
    //       isChecked: false,
    //     },
    //   ],
    // },
    // {
    //   blockName: "Как Вы думаете кто сильнее?",
    //   type: modalTypes.questionsChoiceOfOptionsOne,
    //   list: [
    //     { name: "Акула", isChecked: true },
    //     {
    //       name: "Медведь",
    //       isChecked: false,
    //     },
    //     { name: "Никто", isChecked: false },
    //   ],
    // },
  ],
  dontShow: [],
  personalData: {
    firsName: "",
    lastName: "",
    middleName: "",
    email: "",
    phoneNumber: "",
    gift: "",
  },
};

const answersState = (state = initialStateAnswers, action) => {
  switch (action.type) {
    case INIT_ANSWER_STATE:
      return {
        ...state,
        answers: action.answers.filter((el) => {
          if (el.type === modalTypes.questionsOnScale) {
            for (let i = 0; i < el.list?.length; i++) {
              if (el.list[i].range) {
                el.list[i].range = 0;
              }
            }
          }
          return el;
        }),
      };
    case CHANGE_SCALE:
      return {
        ...state,
        answers: state.answers.map((el, index) => {
          if (index == action.index) {
            return el.range.choose == action.choose ? { ...el, range: { ...el.range, choose: 0 } }
              : index == action.index && el.range != undefined ? { ...el, range: { ...el.range, choose: action.choose } }
                : el
          }
          return el
        }),
      };
    case CHECKED_VARIANTS:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (
            el.type === modalTypes.questionsChoiceOfOptionsMany &&
            el.blockName === action.blockName ||
            el.type === modalTypes.questionsQuizMany &&
            el.blockName === action.blockName
          ) {
            el.list.filter((li) => {
              if (li.name == action.name && li?.onlyThis != undefined) {
                li.isChecked = !li.isChecked;
                for (let i = 0; i < el.list.length; i++) {
                  if (el.list[i]?.onlyThis == undefined) {
                    el.list[i].isChecked = false;
                  }
                }
              } else if (li.name == action.name && li?.onlyThis == undefined) {
                li.isChecked = !li.isChecked;
                for (let i = 0; i < el.list.length; i++) {
                  if (el.list[i]?.onlyThis != undefined) {
                    el.list[i].isChecked = false;
                  }
                }
              }
              return li;
            });
          }
          return el;
        }),
      };
    case CHECKED_VARIANT:
      return {
        ...state,
        answers: state.answers.filter((el, index) => {
          if (
            el.type === modalTypes.questionsChoiceOfOptionsOne &&
            el.blockName === action.blockName || el.type === modalTypes.questionsQuizOne &&
            el.blockName === action.blockName
          ) {
            el.list.filter((li) => {
              if (action.name) {
                if (li.isChecked && li.name !== action.name) {
                  li.isChecked = false;
                }
                if (li.name === action.name && index == action.index) {

                  li.isChecked = !li.isChecked;
                }
              } else {
                li.isChecked = false;
              }
              return li;
            });
          }
          return el;
        }),
      };
    case CHECKED_DONT_SHOW_VARIANT:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (el.blockName === action.blockName) {
            el.list.filter((li) => {
              if (li?.isChecked != undefined) {
                return (li.isChecked = !li.isChecked);
              }
            });
          }
          return el;
        }),
      };
    case ADD_FREE_ANSWER_OTHER_VARIANT:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (el.blockName === action.blockName) {
            el.list.filter((li) => {
              if (li.answer != undefined) {
                return (li.answer = action.answer);
              }
            });
            return el;
          }
          return el;
        }),
      };
    case ADD_FREE_ANSWER:
      return {
        ...state,
        answers: state.answers.filter((el) => {
          if (
            el.type === modalTypes.freeAnswer &&
            el.blockName === action.blockName
          ) {
            el.answer = action.answer;
            return el;
          }
          return el;
        }),
      };
    case ADD_FREE_ANSWER_QUIZ:
      return {
        ...state,
        answers: state.answers.filter((el, index) => {
          if (index == action.index)
            el.list[0].answer = action.text
          return el
        })
      }
    case PUSH_DONT_SHOW:
      return {
        ...state,
        dontShow: state.dontShow.find((num) => num === action.dontShow)
          ? state.dontShow.filter((num) => num != action.dontShow)
          : [...state.dontShow, action.dontShow],
      };
    case SET_GIFT:
      return {
        ...state,
        personalData:
          state.personalData.gift != action.gift
            ? { ...state.personalData, gift: action.gift }
            : { ...state.personalData, gift: "" },
      };
    case SET_PERSONAL_DATA:
      return {
        ...state,
        personalData: action.firsName
          ? { ...state.personalData, firsName: action.firsName }
          : action.lastName
            ? { ...state.personalData, lastName: action.lastName }
            : action.middleName
              ? { ...state.personalData, middleName: action.middleName }
              : action.email
                ? { ...state.personalData, email: action.email }
                : { ...state.personalData, phoneNumber: action.phoneNumber },
      };
    case SET_BET:
      return {
        ...state,
        answers: state.answers.map((el, key) => {
          if (el.type === 'questionsQuizOne' || el.type === 'questionsQuizMany' || el.type === 'questionsQuizFreeText') {
            if (key === action.key) el.bet = action.bet
            // else el.bet = false
          }
          return el
        })
      }
    default:
      return state;
  }
};

export const initAnswerStateAction = (answers) => {
  return { type: INIT_ANSWER_STATE, answers };
};
export const changeScaleAction = (
  index,
  choose
) => {
  return { type: CHANGE_SCALE, index, choose };
};
export const chackedVariantsAction = (blockName, name = null) => {
  return { type: CHECKED_VARIANTS, blockName, name };
};
export const chackedVariantAction = (blockName, name = null, index) => {
  return { type: CHECKED_VARIANT, blockName, name, index };
};
export const addFreeAnswerAction = (blockName, answer) => {
  return { type: ADD_FREE_ANSWER, blockName, answer };
};
export const addFreeAnswerOtherVariantAction = (blockName, answer) => {
  return { type: ADD_FREE_ANSWER_OTHER_VARIANT, blockName, answer };
};
export const addFreeAnswerQuizAction = (text, index) => {
  return { type: ADD_FREE_ANSWER_QUIZ, text, index };
};
export const checkedDontShowVariantAction = (blockName) => {
  return { type: CHECKED_DONT_SHOW_VARIANT, blockName };
};
export const pushDontShowActionAction = (dontShow = null) => {
  return { type: PUSH_DONT_SHOW, dontShow };
};
export const setGiftAction = (gift) => {
  return { type: SET_GIFT, gift };
};
export const setPersonalDataAction = (
  firsName = "",
  lastName = "",
  middleName = "",
  email = "",
  phoneNumber = ""
) => {
  return {
    type: SET_PERSONAL_DATA,
    firsName,
    lastName,
    middleName,
    email,
    phoneNumber,
  };
};
export const setBetAction = (key, bet) => {
  return { type: SET_BET, key, bet }
}

export default answersState;
