import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "../Admin.module.css";
import {
  getTariffOfCurrentUser,
  getUser,
  loginUser,
  singUp,
} from "../../../DAL/dal";
import { setUserDataAction } from "../../../Redux/UserReducer";
import { useDispatch } from "react-redux";
import { AlertModal, ChangePassModal } from "../HtmlElements/Elements";
import inMemoryJWTService from "../../../inMemoryJWT/inMemoryJWT";

const LoginOrSingUp = ({
  setEditMode,
  editMode,
  setEditModeType,
  setTotalSessions,
  editModeType,
  setForEditMode,
  forEditMode,
}) => {
  const [menu, setMenu] = useState("login");
  const [currentState, setCurrentState] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [isShowPass, setIsShowPass] = useState(false);
  const dispatch = useDispatch();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const changeCurrentState = (e) => {
    if (e.target.type === "text") {
      setCurrentState((state) => ({ ...state, name: e.target.value }));
    } else if (e.target.type === "password") {
      setCurrentState((state) => ({ ...state, password: e.target.value }));
      const validator = e.target.value.length > 4 || e.target.value.length == 0;
      setIsValidPassword(validator);
    } else {
      setCurrentState((state) => ({ ...state, email: e.target.value }));
      const validator =
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value) ||
        e.target.value.length == 0;
      setIsValidEmail(validator);
    }
  };

  const changeIsShow = () => {
    setIsShowPass(!isShowPass);
  };

  const sendOnServer = () => {
    if (!isValidEmail) {
      setForEditMode("некорректный email");
      return setEditMode(true);
    }
    if (!isValidPassword) {
      setForEditMode("пароль не должен быть меньше 5 символов");
      return setEditMode(true);
    }

    if (menu === "login") {
      if (!currentState.email) {
        setForEditMode("Пустой email");
        return setEditMode(true);
      }
      if (!currentState.password) {
        setForEditMode("пустой пароль");
        return setEditMode(true);
      }
      setTotalSessions(null);
      loginUser(currentState.password, currentState.email)
        .then((r) => {
          const { accessToken, accessTokenExpiration } = r.data;
          inMemoryJWTService.setToken(accessToken, accessTokenExpiration);
          getUser()
            .then((res) => {
              getTariffOfCurrentUser().then((response) => {
                // уточняем тариф у конкретного пользователя
                const { name, email, role } = res.data;
                dispatch(setUserDataAction(name, email, role, response));
              });
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          setForEditMode(e.response.data);
          setEditMode(true);
          console.log(e);
        });
    } else {
      if (!currentState.name) {
        setForEditMode("пустое имя пользователя");
        return setEditMode(true);
      }
      if (!currentState.email) {
        setForEditMode("пустой email");
        return setEditMode(true);
      }
      if (!currentState.password) {
        setForEditMode("пустой пароль");
        return setEditMode(true);
      }
      const { name, password, email } = currentState;
      setTotalSessions(null);
      singUp(name, password, email)
        .then((r) => {
          const { accessToken, accessTokenExpiration } = r.data;
          inMemoryJWTService.setToken(accessToken, accessTokenExpiration);
          getUser()
            .then((res) => {
              const { name, email, role } = res.data;
              dispatch(setUserDataAction(name, email, role));
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          setForEditMode("Что то пошло не так");
          setEditMode(true);
          console.log(e);
        });
    }
  };

  const selectLoginOrSingUp = useCallback((e) => {
    if (e.target.innerText == "Войти") setMenu("login");
    if (e.target.innerText == "Регистрация") setMenu("singUp");
  }, []);
  const activeStyle = {
    backgroundColor: "white",
    borderRadius: "10em",
    color: "black",
    transition: ".5s",
  };

  // useEffect(() => {
  //   setEditModeType("change_pass");
  // }, []);

  return (
    <>
      {!editMode && !editModeType ? (
        <div className={styles.loginAndSingContainer}>
          <div
            style={menu === "singUp" ? {} : { marginBottom: "-80px" }}
            className={styles.loginOrSingUp}
          >
            <span
              style={menu == "login" ? activeStyle : {}}
              onClick={selectLoginOrSingUp}
            >
              Войти
            </span>
            <span
              style={menu == "singUp" ? activeStyle : {}}
              onClick={selectLoginOrSingUp}
            >
              Регистрация
            </span>
          </div>
          <input
            style={
              menu === "singUp"
                ? {
                    opacity: "1",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
                  }
                : { opacity: "0", zIndex: "-1", transition: "1s" }
            }
            onChange={changeCurrentState}
            value={currentState.name}
            type="text"
            placeholder="Имя"
          />

          <input
            onChange={changeCurrentState}
            value={currentState.email}
            type="email"
            placeholder="Электронная почта"
            style={{
              borderBottom: isValidEmail
                ? "1px solid rgba(255, 255, 255, 0.5)"
                : "1px solid red",
            }}
          />
          <div className={styles.inputDiv}>
            <input
              value={currentState.password}
              onChange={changeCurrentState}
              type={isShowPass ? "text" : "password"}
              placeholder="Пароль"
              style={{
                borderBottom: isValidPassword
                  ? "1px solid rgba(255, 255, 255, 0.5)"
                  : "1px solid red",
              }}
            />
            {!isShowPass ? (
              <img
                onClick={changeIsShow}
                src={require("../../../Images/eye.png")}
                alt=""
              />
            ) : (
              <img
                onClick={changeIsShow}
                src={require("../../../Images/eye-off.png")}
                alt=""
              />
            )}
          </div>
          {menu == "login" ? (
            <>
              <button onClick={sendOnServer}>
                {/* <img src={require("../../Images/key.png")} /> Войти */}
                <img src={require("../../../Images/key.png")} /> Войти
              </button>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setEditModeType("change_pass");
                }}
              >
                Забыли пароль?
              </span>
            </>
          ) : (
            <button onClick={sendOnServer}>
              <img src={require("../../../Images/key.png")} />
              Регистрация
            </button>
          )}
        </div>
      ) : editMode && !editModeType ? (
        <AlertModal
          info={forEditMode}
          setEditMode={setEditMode}
          setEditModeType={setEditModeType}
        />
      ) : (
        <ChangePassModal
          setEditModeType={setEditModeType}
          setForEditMode={setForEditMode}
          setEditMode={setEditMode}
        />
      )}
    </>
  );
};

export default LoginOrSingUp;
