import React, { useCallback, useEffect, useState } from "react";
import style from "./MainResults.module.css";
import VizualResultContainer from "./VizualResultContainer";
import { getCurrentResult, getOrUpdateCurrentScreeen } from "../../DAL/dal";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSessionIdAction } from "../../Redux/QuestionsReducer";
import VizualQuestionsToLector from "./VizualQuestionsToLector";
import VizualResultQuiz from "./VizualResultQuiz";

const MainResults = ({
  questions,
  socket,
  reRender,
  containerColor,
  blockNameColor,
  questionNameColor,
  clientTextColor,
  questionsToLector,
  cases,
  currentScreen,
  setCurrentScreen,
}) => {
  const [result, setResult] = useState([]);
  const { id } = useParams();
  const [forGetCurrentResult, setForGetCurrentResult] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSessionIdAction(id));
  }, [id]);

  useEffect(() => {
    if (cases.length)
      getCurrentResult(id)
        .then((r) => {
          setResult(
            r.data.filter((obj) => {
              delete obj.id;
              return obj;
            })
          );
        })
        .catch((e) => console.log(e));
  }, [reRender, forGetCurrentResult, cases]);
  useEffect(() => {
    socket.on("pushResult", (data) => {
      if (data.id === id) {
        setResult((prev) => [...prev, ...data.requestFirst]);
        // отрисовываем только при условии если пришедший id равен текущему id
      }
    });
    socket.on("resCurrentScreen", (data) => {
      if (data.id == id) {
        setCurrentScreen(data.r);
      }
    });
  }, []);

  return (
    <section className={style.head_vizual_result}>
      {currentScreen == "result" && questions.length ? (
        <>
          {questions.length ? (
            // пока что реализовано так, что не покажет эту компоненту, если найдет тип вопросов с range
            <VizualResultContainer
              reRender={reRender}
              clientTextColor={clientTextColor}
              questionNameColor={questionNameColor}
              blockNameColor={blockNameColor}
              containerColor={containerColor}
              result={result}
              setForGetCurrentResult={setForGetCurrentResult}
              forGetCurrentResult={forGetCurrentResult}
            />
          ) : null}
        </>
      ) : currentScreen == "congress_questions" ? (
        <VizualQuestionsToLector
          clientTextColor={clientTextColor}
          questionNameColor={questionNameColor}
          blockNameColor={blockNameColor}
          containerColor={containerColor}
          questions={questionsToLector}
        />
      ) : (
        <VizualResultQuiz
          reRender={reRender}
          clientTextColor={clientTextColor}
          questionNameColor={questionNameColor}
          blockNameColor={blockNameColor}
          containerColor={containerColor}
          result={result}
          setForGetCurrentResult={setForGetCurrentResult}
          forGetCurrentResult={forGetCurrentResult}
        />
      )}
    </section>
  );
};

export default MainResults;
