import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { compileResultFunction } from "../Utils/utils";
import style from "./allFinalStyle.module.css";
import { quizTestFunc, testFunction } from "./testFunction";
import { useParams } from "react-router-dom";

export const SenderComponent = ({
  socket,
  currentCaseName,
  setWelcome,
  setRunToFinalComponents,
}) => {
  const { answers } = useSelector((state) => state.answers);
  const requestFirst = compileResultFunction(answers);

  return (
    <>
      <SendResult
        setRunToFinalComponents={setRunToFinalComponents}
        setWelcome={setWelcome}
        currentCaseName={currentCaseName}
        socket={socket}
        requestFirst={requestFirst}
      />
    </>
  );
};

const SendResult = ({
  requestFirst,
  socket,
  currentCaseName,
  setWelcome,
  setRunToFinalComponents,
}) => {
  const { id } = useParams();

  useEffect(() => {
    // проверка контактных данных при квизе
    for (let objKey in requestFirst[0].list[0]) {
      if (
        objKey == "id" ||
        objKey == "isCorrect" ||
        objKey == "key" ||
        objKey == "name" ||
        objKey == "range" ||
        objKey == "answer"
      )
        continue;
      else {
        if (!requestFirst[0].list[0][objKey]) {
          const FI = window.localStorage.getItem("FI").split(",");
          switch (objKey) {
            case "Имя":
              for (let i in requestFirst[0].list) {
                requestFirst[0].list[i][objKey] = FI[0];
              }
              break;
            case "Фамилия":
              for (let i in requestFirst[0].list) {
                requestFirst[0].list[i][objKey] = FI[1];
              }
              break;
            case "Отчество":
              for (let i in requestFirst[0].list) {
                requestFirst[0].list[i][objKey] = FI[2];
              }
              break;
            case "Телефон":
              for (let i in requestFirst[0].list) {
                requestFirst[0].list[i][objKey] = FI[3];
              }
              break;
            case "Почта":
              for (let i in requestFirst[0].list) {
                requestFirst[0].list[i][objKey] = FI[4];
              }
              break;
            case "Город":
              for (let i in requestFirst[0].list) {
                requestFirst[0].list[i][objKey] = FI[5];
              }
              break;
            case "Команда":
              for (let i in requestFirst[0].list) {
                requestFirst[0].list[i][objKey] = FI[6];
              }
              break;
            case "Компания":
              for (let i in requestFirst[0].list) {
                requestFirst[0].list[i][objKey] = FI[7];
              }
              break;
            default:
              break;
          }
        }
        // здесь мы делаем дополнительную проверку и записываем в объекты ответов все данные из локал стореде чтобы не дай бог не отправить пустые строки
      }
    }
    socket.emit("sendResult", { requestFirst, id }); // отправляем id из параметров, для идентификации сессии на всех подключенных сокетах
    if (!window.localStorage.getItem("cases") && currentCaseName[0] != "#") {
      window.localStorage.setItem("cases", [id + currentCaseName]);
    } else if (currentCaseName[0] != "#") {
      let storage = window.localStorage.getItem("cases");
      window.localStorage.setItem("cases", [storage, id + currentCaseName]);
      // console.log(storage);
    }
    if (currentCaseName[0] != "#") {
      setWelcome(false);
      setRunToFinalComponents(false);
    }
  }, []);

  // useEffect(() => {
  //   let count = 0;
  //   testFunction(socket, id, count);
  //   // setTimeout(() => {
  //   //   window.location.reload();
  //   // }, 5000);
  // }, []);

  // useEffect(() => {
  //   let count = 0;
  //   quizTestFunc(socket, id, count);
  //   // setTimeout(() => {
  //   //   window.location.reload();
  //   // }, 5000);
  // }, []);

  return (
    <>
      <section className={style.main}>Спасибо за Ваш ответ!</section>
    </>
  );
};
