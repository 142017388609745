export const compile_vote_list = (result, sheet_vote_par, columns = null) => { // группирует строки и колонки для листа 'голосование'

    if (!columns) { // если параметр колонок пустой, то моделируем и возвращаем колонки для определения их в родительской функции
        const arr = [
            ...result.map((el) => {
                return Object.entries(el).map((li) => {
                    return {
                        key: `${li[0]}`,
                        width: 40,
                    };
                });
            }),
        ];
        const final = [];
        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < arr[i].length; j++) {
                final.push(arr[i][j]);
            }
        }
        const newArr = final
            .filter((el, index) => {
                if (el.key == "вопросы" && index === 0) {
                    return el.key;
                } else if (el.key != "вопросы") {
                    return el;
                }
            })
            .filter(
                (elem, index, self) =>
                    self.findIndex((t) => {
                        return t.key === elem.key;
                    }) === index
            );

        return newArr
    }
    else { // а здесь уже минуя первую часть функции моделируем строки, используя соответствующий переданный параметр 
        sheet_vote_par.addRow(
            columns.map((li) => {
                return (li.key = li.key);
            })
        );

        for (let i = 0; i < result.length; i++) {
            sheet_vote_par.addRow(result[i]);
        }
        sheet_vote_par.getRow(1).fill = {
            type: "pattern",
            pattern: "darkHorizontal",
            fgColor: { argb: "FFFF00" },
        };
        sheet_vote_par.getRow(1).font = {
            name: "Comic Sans MS",
            family: 4,
            size: 16,
            bold: true,
        };
        return sheet_vote_par
    }
}


export const compile_ask_list = (result, sheet_ask_par, columns = null) => {



}