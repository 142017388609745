import React, { useEffect, useState } from "react";
import style from "./CasesPanel.module.css";
import Excel from "../Excel/Excel";
import {
  changeModerationRole,
  clearAllData,
  clearOneData,
  getAllResults,
  getCurrentModerator,
  getCurrentResult,
  getOrUpdateCurrentScreeen,
} from "../../DAL/dal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setSessionIdAction } from "../../Redux/QuestionsReducer";
import Moderation from "./Moderation/Moderation";
import ApproveQuestions from "./Moderation/ApproveQuestions";

const CasesPanel = ({
  socket,
  reRender,
  cases,
  currentIndex,
  setCurrentIndex,
  questionsToLector,
  setQuestionsToLector,
  isAsk,
  sortFunction,
  currentScreen,
  setCurrentScreen,
  height,
}) => {
  const [currentCaseName, setCurrentCaseName] = useState("");
  const questions = useSelector((state) => state.questions);
  const [dataExport, setDataExport] = useState([]);
  const [result, setResult] = useState([]);
  const [isOneQuestionOnCase, setIsOneQuestionOnCase] = useState(false);
  const [deleteAllAnswers, setDeleteAllAnswers] = useState(false);
  const [deleteOneAnswers, setDeleteOneAnswers] = useState(false);
  const [openModarate, setOpenModarate] = useState("0vw");
  const [openApproveQuestions, setOpenApproveQuestions] = useState("0vw");
  const [moderator, setModerator] = useState(true);
  const [render, setRender] = useState(false); // состояние для того чтобы ползунок модератора сразу появлялся в нужном месте
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (isAsk)
      getCurrentModerator(id).then((r) => {
        setModerator(r);
        setRender(true);
      });
  }, [isAsk]);

  useEffect(() => {
    dispatch(setSessionIdAction(id));
  }, [id]);

  useEffect(() => {
    if (cases.length) {
      const arrayValidationLength = [];
      for (let i = 0; i < cases.length; i++) {
        if (cases[i].questions?.length === 1) {
          arrayValidationLength.push("/");
        }
      }
      if (arrayValidationLength.length === cases.length) {
        setIsOneQuestionOnCase(true);
      }
    }
  }, [cases]);

  const setGlobalCaseIndex = (index, id) => {
    // if (index > cases.length - 2) return setCurrentIndex(index);
    socket.emit("setCase", { index, id });
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (cases.length) {
      setCurrentCaseName(cases[currentIndex]?.caseName);
    }
  }, [cases, currentIndex]);

  useEffect(() => {
    if (isOneQuestionOnCase) {
      getAllResults(id).then((r) => {
        const { answersdata } = r.data;
        setDataExport(answersdata);
      });
    } else {
      getCurrentResult(id).then((r) => {
        setDataExport(r.data);
      });
    }
  }, [reRender, isOneQuestionOnCase]);
  useEffect(() => {
    const array = [];
    if (isOneQuestionOnCase) {
      cases.map((el) => {
        return array.push({ name: el.caseName, list: [], listRange: [] });
      });
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < dataExport[i]?.length; j++) {
          for (let k = 0; k < dataExport[i][j]?.list.length; k++) {
            if (
              cases[i].questions.find((li) =>
                li.list?.find(
                  (obj) =>
                    obj.name === dataExport[i][j]?.list[k].name &&
                    dataExport[i][j]?.list[k].key != undefined
                )
              )
            ) {
              array[i].list.push(dataExport[i][j]?.list[k]?.name);
            }
            if (
              dataExport[i][j]?.list[k].range &&
              dataExport[i][j]?.list[k].key != undefined
            ) {
              array[i].listRange.push(dataExport[i][j]?.list[k]?.range);
            }
          }
        }
      }
    } else {
      questions.questions.map((el) => {
        return array.push({ name: el.blockName, list: [], listRange: [] });
      });
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < dataExport.length; j++) {
          for (let k = 0; k < dataExport[j].list.length; k++) {
            if (dataExport[j].list[k].key != undefined) {
              if (i === dataExport[j]?.list[k].key) {
                if (dataExport[j].list[k]?.range) {
                  array[i].listRange.push(dataExport[j].list[k]?.range);
                } else array[i].list.push(dataExport[j].list[k]?.name);
                // добавляем ответы относящиеся к конкретному вопросу
              }
            }
          }
        }
      }
    }

    const newArray = [];
    for (let i = 0; i < array.length; i++) {
      // фильтруем варианты в новый массив с колличеством подсчитанных ответов
      newArray.push({
        вопросы: array[i].name,
        ...array[i].list.reduce((acc, j) => {
          if (acc.hasOwnProperty(j)) {
            acc[j] += 1;
          } else {
            acc[j] = 1;
          }
          return acc;
        }, {}),
        оценка:
          (
            array[i].listRange.reduce((a, b) => a + b, 0) /
            array[i].listRange.length
          ).toFixed(1) + `/кол-во голосов ${array[i].listRange.length}`,
      });
    }
    for (let i in newArray) {
      if (newArray[i].оценка.slice(0, 3) == "NaN") delete newArray[i].оценка;
    }

    setResult(newArray);
  }, [dataExport, currentCaseName, isOneQuestionOnCase]);

  const [questionsFoModeration, setQuestionsFoModeration] = useState([]); // чтобы отсюда проверять длину массива и управлять состоянием

  const changeScreen = (screen) => {
    getOrUpdateCurrentScreeen(id, screen).then((r) => {
      setCurrentScreen(r);
      socket.emit("setCurrentScreen", { r, id }); // каждый раз при изменении скрина
    });
  };

  return (
    <>
      <ApproveQuestions
        sortFunction={sortFunction}
        openApproveQuestions={openApproveQuestions}
        setOpenApproveQuestions={setOpenApproveQuestions}
        id={id}
        socket={socket}
        questions={questionsToLector}
        setQuestions={setQuestionsToLector}
      />
      <Moderation
        isAsk={isAsk}
        questions={questionsFoModeration}
        setQuestions={setQuestionsFoModeration}
        open={openModarate}
        setOpenModarate={setOpenModarate}
        socket={socket}
        id={id}
        reRender={reRender}
      />
      <section
        className={style.case_panel_container}
        style={{
          maxHeight: height * 0.98,
          // paddingBottom:isAsk?'400px':''
        }}
      >
        {isAsk && (
          <div
            onClick={() =>
              setOpenApproveQuestions(window.innerWidth < 700 ? "80vw" : "50vw")
            }
            className={style.approve_questions}
          >
            « Окно вопросов
          </div>
        )}
        {questionsFoModeration.length ? (
          <div
            className={style.notification}
            onClick={() => {
              if (openModarate == "0vw")
                setOpenModarate(window.innerWidth < 700 ? "80vw" : "50vw");
              else setOpenModarate("0vw");
            }}
          >
            {questionsFoModeration.length}
          </div>
        ) : null}
        <div className={style.block_container}>
          {cases.map((el, index) => (
            <div
              style={{
                background: `${
                  currentIndex === index
                    ? "#272a55"
                    : "rgba(216, 244, 252, 0.95)"
                } `,
                color: `${currentIndex === index ? "white" : "black"} `,
                borderRadius: "10px",
                padding: "10px 30px",
                cursor: "pointer",
                transform: `${
                  currentIndex === index ? "scale(1.05)" : "scale(1)"
                }`,
                transition: ".8s",
                width: "100%",
              }}
              onClick={() => {
                setGlobalCaseIndex(index, id);
              }}
              key={index}
            >
              {el.caseName}
            </div>
          ))}
        </div>
        <Excel
          cases={cases}
          questions={questions}
          result={result}
          caseName={currentCaseName}
          setDeleteAllAnswers={setDeleteAllAnswers}
          setDeleteOneAnswers={setDeleteOneAnswers}
          questionsToLector={questionsToLector}
        />
        {isAsk && (
          <>
            {/* <div className={style.changeModerator}>
              <div>Модератор:</div>
              {render && (
                <div
                  onClick={() =>
                    changeModerationRole(id).then((r) => setModerator(r))
                  }
                  className={style.moderator}
                  style={moderator ? { padding: "10px 23px 10px 20px" } : {}}
                >
                  <div
                    style={
                      moderator
                        ? { color: "rgba(255, 255, 255, 0.5)" }
                        : { color: "black" }
                    }
                    className={style.human}
                  >
                    человек
                  </div>
                  <section
                    style={moderator ? { transform: "translateX(100%)" } : {}}
                    className={style.currentModerator}
                  ></section>
                  <div
                    style={
                      moderator
                        ? { color: "black" }
                        : { color: "rgba(255, 255, 255, 0.5)" }
                    }
                    className={style.robot}
                  >
                    робот
                  </div>
                </div>
              )}
            </div> */}
            {cases.length ? (
              <div className={style.changeModerator}>
                <div>Экран:</div>
                {render && (
                  <div
                    onClick={() => {
                      changeScreen(
                        currentScreen == "congress_questions"
                          ? "congress_questions"
                          : "result"
                      );
                    }}
                    className={style.screen}
                    style={
                      currentScreen == "congress_questions"
                        ? { padding: "10px 23px 10px 20px" }
                        : {}
                    }
                  >
                    <div
                      style={
                        currentScreen == "congress_questions"
                          ? { color: "rgba(255, 255, 255, 0.5)" }
                          : { color: "black" }
                      }
                      className={style.human}
                    >
                      результаты
                    </div>
                    <section
                      style={
                        currentScreen == "congress_questions"
                          ? { transform: "translateX(100%)" }
                          : {}
                      }
                      className={style.currentModerator}
                    ></section>
                    <div
                      style={
                        currentScreen == "congress_questions"
                          ? { color: "black" }
                          : { color: "rgba(255, 255, 255, 0.5)" }
                      }
                      className={style.robot}
                    >
                      вопросы
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </section>
      {deleteAllAnswers && (
        <section className={style.warning}>
          <h2>
            Это приведет к удалению всех ответов во всех блоках вопросов, Вы
            уверены что хотите это сделать?
          </h2>
          <div className={style.warningButtons}>
            <button
              onClick={() => {
                clearAllData(id).then((r) => setDeleteAllAnswers(false));
              }}
              style={{ background: "green" }}
            >
              Да
            </button>
            <button
              onClick={() => {
                setDeleteAllAnswers(false);
              }}
              style={{ background: "red" }}
            >
              Нет
            </button>
          </div>
        </section>
      )}
      {deleteOneAnswers && (
        <section className={style.warning}>
          <h2>
            Это приведет к удалению всех ответов текущем блоке вопросов, Вы
            уверены что хотите это сделать?
          </h2>
          <div className={style.warningButtons}>
            <button
              onClick={() => {
                clearOneData(id).then((r) => setDeleteOneAnswers(false));
              }}
              style={{ background: "green" }}
            >
              Да
            </button>
            <button
              onClick={() => {
                setDeleteOneAnswers(false);
              }}
              style={{ background: "red" }}
            >
              Нет
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default CasesPanel;
