import React from "react";
import styles from "./welcome.module.css";

const TestParssed = ({
  clientTextColor,
  containerColor,
  questionNameColor,
  questionsCount,
  isLastQuestion,
}) => {
  return (
    <section style={{ background: containerColor }} className={styles.main}>
      <h2 style={{ color: clientTextColor }}>Спасибо!</h2>
      <span
        className={styles.info_after_ask}
        style={{
          color: questionNameColor,
        }}
      >
        {questionsCount > 1 ? (
          <>
            {isLastQuestion
              ? "Ваши ответы учтены, благодарим за Ваше участие!"
              : "Ваши ответы учтены, следующий вопрос/вопросы появятся на вашем экране автоматически"}
          </>
        ) : (
          <>
            {isLastQuestion
              ? "Ваш ответ учтен, благодарим за Ваше участие!"
              : "Ваш ответ учтен, следующий вопрос/вопросы появятся на вашем экране автоматически"}
          </>
        )}
        {/* Ваш голос учтён. Следующий вопрос появится на вашем экране
        автоматически. */}
      </span>
    </section>
  );
};

export default TestParssed;
