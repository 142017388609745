import { Route, Routes, useNavigate } from "react-router-dom";
import styles from "./Styles.module.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AddAsk,
  AlertModal,
  EditBlockName,
  NewSessionIsCreated,
} from "../HtmlElements/Elements";
import StylesCase from "../StylesCase";
import BlockList from "./BlockList";
import Form from "../CompileForm/Form";

export const CompileQuesions = ({
  blockQuestions,
  setBlockQuestions,
  questionObject,
  editMode,
  setEditMode,
  sessionName,
  setSessionName,
  initCaseNameState,
  session_status,
  setFileData,
  colors,
  setColors,
  editModeType,
  setEditModeType,
  currentSessionId,
  indexForEdit,
  setIndexForEdit,
  role,
  addNewBlockForEdit,
  isOnlyAsk,
  setIsOnlyAsk,
  isAsk,
  setIsAsk,
  previewImage,
  setPreviewImage,
  height,
  alertInfo,
  setAlertInfo,
  addNewCopyBlockForEdit,
}) => {
  const navigate = useNavigate();
  const [init, setInit] = useState(false); // для того чтобы один раз загрузить спрятанные вопросы для компоненты созданного кейса
  const [hideBlock, setHideBlock] = useState([]); // если находит елемент равный индексу по прячет

  useEffect(() => {
    const arr = [];
    if (
      window.location.hash != "#/admin/create-session" &&
      !init &&
      blockQuestions.length
    ) {
      // загружает свернутыми все блоки вопросов для компоненты текущего кейса
      for (let i = 0; i < blockQuestions.length; i++) {
        arr.push(i);
      }
      setHideBlock(arr);
      setInit(true);
    }
  }, [blockQuestions]);

  const onChangeBlockName = (text, index) => {
    if (setBlockQuestions == null) return;
    setBlockQuestions((prev) =>
      prev.map((el, num) => {
        return num === index ? { ...el, caseName: text } : el;
      })
    );
  };

  const moveBlockListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = blockQuestions[dragIndex];
      const hoverItem = blockQuestions[hoverIndex];
      // Swap places of dragItem and hoverItem in the pets array
      setBlockQuestions((blocks) => {
        const updatedBlocks = [...blocks];
        updatedBlocks[dragIndex] = hoverItem;
        updatedBlocks[hoverIndex] = dragItem;
        return updatedBlocks;
      });
    },
    [blockQuestions]
  );

  const element = useMemo(() => {
    return editModeType == "block_name" ? ( // тернарником проверяем что именно рендерить в edit mode
      <EditBlockName
        setEditMode={setEditMode}
        index={indexForEdit}
        setState={onChangeBlockName}
        state={blockQuestions}
        setEditModeType={setEditModeType}
      />
    ) : editModeType == "session_name" ? (
      <EditBlockName
        setEditMode={setEditMode}
        setState={setSessionName}
        state={sessionName}
        setEditModeType={setEditModeType}
      />
    ) : editModeType == "session_created" ? (
      <NewSessionIsCreated
        setEditMode={setEditMode}
        currentSessionId={currentSessionId}
      />
    ) : editModeType == "add-ask" ? (
      <AddAsk
        isAsk={isAsk}
        id={currentSessionId}
        setEditMode={setEditMode}
        setIsAsk={setIsAsk}
      />
    ) : (
      <AlertModal
        info={alertInfo}
        setEditMode={setEditMode}
        setEditModeType={setEditModeType}
      />
    );
  }, [editMode, isAsk]); // реагирует на открытие и закрытие окна редактирования

  const addNewBlock = () => {
    // добавляет новый блок
    if (setBlockQuestions == null) return;
    setBlockQuestions((prev) => [...prev, { ...questionObject }]);
    initCaseNameState(); // далее уже каждому очередному элементу в соответствии с его порядковым номером
  };
  const addNewCopyBlock = () => {
    // добавляет новый блок
    if (setBlockQuestions == null) return;
    const editBlockQuestions = structuredClone(blockQuestions);
    setBlockQuestions((prev) => [
      ...prev,
      blockQuestions[blockQuestions.length - 1].caseName ==
      "Блок результатов квиза"
        ? editBlockQuestions[blockQuestions.length - 2]
        : editBlockQuestions[blockQuestions.length - 1],
    ]);
    // initCaseNameState(); // далее уже каждому очередному элементу в соответствии с его порядковым номером
  };
  const hideElFunction = (index) => {
    // функция которая отвечает за сворачивание блоков
    if (hideBlock.includes(index))
      setHideBlock(hideBlock.filter((el) => el != index));
    else setHideBlock((prev) => [...prev, index]);
  };

  const unitedFunction = (e) => {
    // одна функция на 2 кнопки
    if (e.target.alt == "Назад") navigate("/admin");
  };

  function addAskForCreatedSession() {
    // функция добаления вопросов спикеру уже для рабочей сессии
    setEditModeType("add-ask");
    setEditMode(true);
    // if (isAsk) {
    //   createNewAskOrDelete(currentSessionId, false).then((r) =>
    //     setIsAsk(false)
    //   );
    // } else {
    //   createNewAskOrDelete(currentSessionId, true).then((r) => setIsAsk(true));
    // }

    // создаем или удаляем сессию в зависимости от ключа isAsk
  }

  return (
    <>
      {editMode ? (
        <>
          {element}
          <img
            className={styles.clouse_edit_mode}
            onClick={() => {
              setEditMode(false);
            }}
            src={require("../../../Images/clouse.png")}
            alt=""
          />
        </>
      ) : (
        <section
          style={
            window.location.hash != "#/admin/create-session" &&
            window.location.hash != "#/admin/create-session/styles"
              ? { paddingBottom: 0, height: height }
              : { height: height }
          }
          className={styles.compiled_sessions_container}
        >
          <div className={styles.session_line_name}>
            {(window.location.hash == "#/admin/create-session" ||
              window.location.hash == "#/admin/create-session/styles") && (
              <>
                <h1 className={styles.session_title}>
                  {sessionName}
                  <img
                    onClick={() => {
                      setEditModeType("session_name");
                      setEditMode(true);
                    }}
                    src={require("../../../Images/Button_opacity.png")}
                    alt=""
                  />
                </h1>
                <img
                  onClick={unitedFunction}
                  src={require("../../../Images/clouse.png")}
                  alt="Назад"
                />
              </>
            )}
          </div>
          <h2 className={styles.steps}>
            {window.location.hash == "#/admin/create-session"
              ? " Шаг 1 из 2. Настройка вопросов"
              : window.location.hash == "#/admin/create-session/styles"
              ? "Шаг 2 из 2. Настройка дизайна"
              : window.location.hash == "#/admin/create-session/form"
              ? ""
              : "Вопросы"}
          </h2>
          <div>
            {(window.location.hash == "#/admin/create-session" ||
              window.location.hash.slice(0, 23) ==
                "#/admin/current-session") && (
              <div className={styles.onlyAsk}>
                <p>Добавить возможность задавать вопросы</p>
                <div
                  onClick={() =>
                    window.location.hash == "#/admin/create-session"
                      ? setIsAsk(!isAsk)
                      : addAskForCreatedSession()
                  }
                  style={
                    isAsk
                      ? {
                          backgroundColor: "rgb(76, 250, 76)",
                          justifyContent: "flex-end",
                        }
                      : {}
                  }
                  className={styles.switch}
                >
                  <div></div>
                </div>
              </div>
            )}

            {window.location.hash == "#/admin/create-session" && isAsk && (
              <div className={styles.onlyAsk}>
                <p>Требуется только задавание вопросов</p>
                <div
                  onClick={() => setIsOnlyAsk(!isOnlyAsk)}
                  style={
                    isOnlyAsk
                      ? {
                          backgroundColor: "rgb(76, 250, 76)",
                          justifyContent: "flex-end",
                        }
                      : {}
                  }
                  className={styles.switch}
                >
                  <div></div>
                </div>
              </div>
            )}
          </div>
          <Routes>
            <Route
              path="/styles"
              element={
                <StylesCase
                  setFileData={setFileData}
                  colors={colors}
                  setColors={setColors}
                  previewImage={previewImage}
                  setPreviewImage={setPreviewImage}
                  blockQuestions={blockQuestions}
                  height={height}
                />
              }
            />
            <Route
              path="/form"
              element={
                <Form
                  setBlockQuestions={setBlockQuestions}
                  blockQuestions={blockQuestions}
                />
              }
            />
            <Route
              path="/"
              element={
                <>
                  <div className={styles.all_blocks}>
                    {blockQuestions?.map(
                      (
                        el,
                        index // мапим блоки вопросов
                      ) => {
                        if (el.caseName != "Блок результатов квиза")
                          return (
                            <BlockList
                              key={index}
                              el={el}
                              setAlertInfo={setAlertInfo}
                              setEditMode={setEditMode}
                              blockQuestions={blockQuestions}
                              setBlockQuestions={setBlockQuestions}
                              index={index}
                              hideBlock={hideBlock}
                              setEditModeType={setEditModeType}
                              setIndexForEdit={setIndexForEdit}
                              hideElFunction={hideElFunction}
                              moveBlockListItem={moveBlockListItem}
                            />
                          );
                      }
                    )}
                  </div>
                  {!isOnlyAsk && (
                    <div className={styles.add_block_wrapper}>
                      {(window.location.hash == "#/admin/create-session" ||
                        role == "admin" ||
                        session_status == "не активна" ||
                        setBlockQuestions) && (
                        <div className={styles.add_block}>
                          <img
                            title="Добавить новый блок вопросов"
                            onClick={
                              window.location.hash == "#/admin/create-session"
                                ? addNewBlock
                                : addNewBlockForEdit
                            }
                            src={require("../../../Images/Icon.png")}
                            alt=""
                          />
                          Добавить блок вопросов
                          <img
                            title="Скопировать предыдущий"
                            onClick={
                              window.location.hash == "#/admin/create-session"
                                ? addNewCopyBlock
                                : addNewCopyBlockForEdit
                            }
                            style={{ cursor: "pointer" }}
                            src={require("../../../Images/копия пред.png")}
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
              }
            />
          </Routes>
        </section>
      )}
    </>
  );
};
