import confetti from "canvas-confetti";

export const compileResultFunction = (answers) => {
  let array = [{ list: [] }];
  for (let i = 0; i < answers.length; i++) {
    if ((answers[i].type == 'questionsQuizOne' ||
      answers[i].type == 'questionsQuizMany') && answers[i]?.list?.find((li) => li?.isChecked)) {
      const obj = {}
      for (let key_j in answers[i]) {
        if (key_j == 'Имя' || key_j == 'Фамилия' || key_j == 'Отчество' || key_j == 'Телефон' || key_j == 'Почта' || key_j == 'Город' || key_j == 'Команда' || key_j == 'Компания') {
          obj[key_j] = answers[i][key_j]
        }
      }
      array[0].list.push(
        ...answers[i].list.filter((li) => li?.isChecked).map(li => { return { name: li.name, key: i, id: li.id, isCorrect: li.isCorrect, bet: answers[i].bet, ...obj } })
      );
    } else
      if (answers[i].type == 'questionsQuizFreeText') {
        const obj = {}
        for (let key_j in answers[i]) {
          if (key_j == 'Имя' || key_j == 'Фамилия' || key_j == 'Отчество' || key_j == 'Телефон' || key_j == 'Почта' || key_j == 'Город' || key_j == 'Команда' || key_j == 'Компания') {
            obj[key_j] = answers[i][key_j]
          }
        }
        array[0].list.push(
          ...answers[i].list.map(li => { return { name: li.answer, question: li.name, key: i, id: li.id, bet: answers[i].bet, type: 'free', ...obj } })
        );
      }
      else if ((answers[i].type == 'questionsChoiceOfOptionsMany' || answers[i].type == 'questionsChoiceOfOptionsOne')) {
        array[0].list.push(
          ...answers[i]?.list?.filter((li) => li?.isChecked).map(li => { return { name: li.name, key: i, id: li.id } })
        );
      }
    if (answers[i]?.range?.choose) {
      array[0].list.push({ range: answers[i]?.range?.choose, key: i, id: answers[i]?.range?.id }
      );
    }
  }
  return array;
};

export const form = [
  {
    value: "Имя",
    type: "",
  },
  {
    value: "Фамилия",
    type: "",
  },
  {
    value: "Отчество",
    type: "",
  },
  {
    value: "Телефон",
    type: "",
  },
  {
    value: "Почта",
    type: "",
  },
  {
    value: "Город",
    type: "",
  },
  {
    value: "Команда",
    type: "",
  },
  {
    value: "Компания",
    type: "",
  },
]
export const throwConfetti = (x, y) => {
  confetti({
    particleCount: 50,
    startVelocity: 15,
    spread: 1000,
    origin: { x: x, y: y },
  });
}


