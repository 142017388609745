import React, { useEffect, useState } from "react";
import styles from "./moderation.module.css";
import {
  deleteQuestionsForModeration,
  getQuestionsForModeration,
} from "../../../DAL/dal";

const Moderation = ({
  open,
  socket,
  id,
  questions,
  setQuestions,
  setOpenModarate,
  isAsk,
  reRender,
}) => {
  useEffect(() => {
    if (isAsk) getQuestionsForModeration(id).then((r) => setQuestions(r));
  }, [isAsk, reRender]);

  useEffect(() => {
    socket.on("sendForModeration", (data) => {
      if (data.id == id)
        setQuestions((prev) =>
          [...prev, data.question].sort((a, b) => b.range - a.range)
        );
    });
  }, [socket]);

  window.questions = questions;

  return (
    <>
      <div style={{ minWidth: open }} className={styles.modaration_screen}>
        <div
          onClick={() => setOpenModarate("0vw")}
          style={{ color: "white", cursor: "pointer", padding: "20px" }}
        >
          ✕
        </div>
        {open != "0vw" && (
          <div className={styles.container}>
            {questions.map((el, index) => {
              const approveFunc = () => {
                deleteQuestionsForModeration(id, index).then((r) => {
                  setQuestions((prev) =>
                    prev.filter((el, count) => count != index && el)
                  );
                  socket.emit("setQuestion", {
                    question: questions[index],
                    id,
                  });
                });
              };
              const deleteQuestion = () => {
                deleteQuestionsForModeration(id, index).then((r) => {
                  setQuestions((prev) =>
                    prev.filter((el, count) => count != index && el)
                  );
                });
              };
              return (
                <div
                  style={{ background: index % 2 ? "#3d3d3d88" : "none" }}
                  className={styles.question_case}
                  key={index}
                >
                  <div className={styles.questionBody}>{el.question}</div>
                  <div>
                    <div className={styles.approve} onClick={approveFunc}>
                      ✓ <span>Утвердить</span>
                    </div>
                    <div className={styles.reject} onClick={deleteQuestion}>
                      ✕ <span>Отклонить</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Moderation;
